import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import { createEvent, sample } from 'effector'

import { buildEstimationStatusKey } from '@gmini/common/lib/Services/EstimationService'

import { estimationService } from '../../../services/estimationService'
import { initializeContentView } from '../EstimationReport/ToggleContent/model'
import { createReportDiffService } from '../EstimationReport/model/createReportDiffService'

export const resetEstimationStatus = createEvent<void>()

export const currentEstimationStatus$ = sample({
  source: estimationService.estimation.currentEstimation$,
  clock: estimationService.estimation.estimationStatus$,
  fn: (estimation, statusMap) =>
    estimation && statusMap
      ? statusMap[
          buildEstimationStatusKey({
            estimationId: estimation.id,
            estimationVersion: estimation.version,
          })
        ] || null
      : null,
}).reset(resetEstimationStatus)

export const {
  previewResultDiff$,
  fetchReportResultDiff,
  reportPending$,
  isGroupItem,
} = createReportDiffService({
  entity$: estimationService.estimation.currentEstimation$,
  status$: currentEstimationStatus$,
  reset: api.Estimation.start.trigger,
})

previewResultDiff$.updates.watch(state => {
  if (!state || !state.reportByRootGroupId) {
    return
  }

  Object.keys(state.reportByRootGroupId).forEach((key: string) => {
    const numericKey = Number(key)
    const data = state.reportByRootGroupId[numericKey]

    if (data && data.length > 0) {
      initializeContentView({
        [key]: data.map((_item: unknown, index: number) => ({
          index,
          state: true,
        })),
      })
    }
  })
})
