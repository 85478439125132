import React, { memo, ReactNode, useCallback, useEffect, useMemo } from 'react'
import { Box } from '@gmini/ui-kit/lib/Box'

import {
  combine,
  createEvent,
  forward,
  guard,
  merge,
  restore,
  sample,
} from 'effector'
import { useSnackbar } from 'notistack'

import { useStore, useStoreMap } from 'effector-react'

import { useHistory } from 'react-router-dom'
import { prop } from 'ramda'

import { useKeycloak } from '@react-keycloak/web'
import {
  PageContentContainer,
  useNavbar,
  VersionStatusSwitch,
  VersionStatus,
  RenderVersionButtonBrandProps,
  VersionStatusInfo,
} from '@gmini/common/lib/components'

import {
  Header,
  HeaderLogoWrap,
  Logo,
  SeparatorList,
  PlusCircle,
  IconButton,
  LayersWithoutSubstrate,
  Tooltip,
  FolderWithPlus,
  CountBig,
  CheckList as CheckListIcon,
  Resizable,
  ResizablePanel,
  ResizableRefApi,
  ResizableColumnStateItem,
  Cover,
  Substrate,
  FolderWithoutSubstrate,
  WithCursorIcon,
  Snackbar,
  SuccessfulContentPaste,
  Import,
} from '@gmini/ui-kit'

import { TreeLoader } from '@gmini/ui-kit/lib/TreeLoader/TreeLoader'

import { isNotEmpty } from '@gmini/utils'

import { getNode } from '@gmini/common/lib/classifier-service'

import { createUserClassifierRepoTreeModel } from '@gmini/common/lib/classifier-editor/SelectDependencies/ClassifiersTree/createTreeModel'

import { getFilterIsActiveStore } from '@gmini/common/lib/components/FilterPanel/utils/getFilterIsActiveStore'

import * as forgeViewer from '@gmini/common/lib/forge-viewer'
import { createModelManageMenu } from '@gmini/common/lib/classifier-editor/ModelManageMenu'
import * as filter from '@gmini/common/lib/classifier-editor/DependencyTree/Filters/InclusionFilter/FilterModel'

import {
  createLockModel,
  DragLayer,
  isApiFlatNode,
  ModelSelect,
  SelectDependencies,
  openExplorer,
  createSelectDependenciesParams,
  createViewerDisplayModel,
  selectedModels as selectedModelsService,
  importEstimationService,
  ImportEstimation,
} from '@gmini/common/lib/classifier-editor'

import { useInclusionFilter } from '@gmini/common/lib/classifier-editor/DependencyTree/Filters'

import { createModelStoreService } from '@gmini/common/lib/classifier-editor/ModelSelect/modelStore'

import { createModelsTree } from '@gmini/common/lib/classifier-editor/SelectDependencies/ModelsTree'

import { createClassifiersTree } from '@gmini/common/lib/classifier-editor/SelectDependencies/ClassifiersTree/ClassifiersTree'

import { DependencyTreeWrap } from '@gmini/common/lib/classifier-editor/DependencyTree/DependencyTreeWrap'

import {
  createExpandModel,
  setCurrentGroup,
  copiedFolder$,
  resetCopyFolder,
} from '@gmini/common/lib/classifier-editor/ClassifierTree/model'

import {
  useDynamicGroupMode,
  useReadonlyMode,
  VersionButton,
  VersionHistory,
  ProjectBadge,
  getModulesLinkFromEnv,
  createOneTimeSubscription,
} from '@gmini/common'

import { userInfo$ } from '@gmini/common/lib/auth/auth'

import {
  useNamedVersions,
  useVersionName,
} from '@gmini/common/lib/components/VersionSwitch/NamedVersions'

import { VersionList } from '@gmini/common/lib/components/VersionSwitch/VersionList'
import { useContextMenu } from '@gmini/common/lib/components/VersionSwitch/ContextMenu'
import { createVersionHistoryStore } from '@gmini/common/lib/components/VersionSwitch/versionHistoryStore'
import {
  compareDates,
  fromDateTimeToDate,
  sortByDate,
} from '@gmini/common/lib/helpers/versionDateCommon'
import { createBimFileSubscriptions } from '@gmini/common/lib/classifier-editor/bimFileSubscriptions'

import { goals } from '@gmini/common/lib/metrika'

import { createStatusPanel } from '@gmini/common/lib/classifier-editor/StatusPanel'
import { getBimNode } from '@gmini/common/lib/classifier-editor/Search/utils'

import {
  getViewerId,
  validateModelTypes,
  getViewerModelUrl,
  fetchViewerToken,
} from '@gmini/common/lib/classifier-editor/Common/utils'
import * as smApi from '@gmini/sm-api-sdk'

import {
  isAssemblyClassifierNode,
  isReferenceNode,
  isUserClassifierNode,
  Node,
} from '@gmini/common/lib/classifier-service/Node'

import { EstimationEntity } from '@gmini/common/lib/Services/EstimationService'
import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'
import { adapter } from '@gmini/common/lib/classifier-service/adapters'

import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import { classifierService } from '../../services/classifierService'
import { notificationService } from '../../services/notificationService'

import { estimationService } from '../../services/estimationService'
import { CurrentEstimation } from '../CurrentEstimation'
import { useSelectedGroupNode } from '../CurrentCalculation'
import { GroupSettings } from '../GroupSettings'

import { currentUserClassifier$, projectUrn$ } from '../CurrentUserClassifier'
import { conditionChanged$ } from '../GroupSettings/conditions'
import { userClassifierRepoService } from '../../services/userClassifierRepoServiceConnect'
import {
  checkStatusGrouping,
  groupingProcessStatus$,
  resetGroupingStatus,
  startGrouping,
} from '../GroupSettings/GroupingsContainer/GroupingContainer.model'

import { envLinks, seoEventManager } from '../../config'

import { RunEstimation } from './RunEstimation'

import './model/current/estimation.init'
import './model/current/fetchConditions.init'
import { sourceClassifiersLoaded$ } from './model/current/fetchSourceCls'
import { dynamicGroupPending$, EditorTreeWrap } from './EditorTreeWrap'

import {
  dependencyCheckedModel,
  editorCheckedModel,
} from './model/current/checkedModel'
import { dependencyTreeModel } from './model/current/dependencyTreeModel'
import { treeModel } from './model/current/editorTreeModel'
import {
  filteredFlatTree$,
  inclusionStore$,
  loadNodeInclusion,
  loadNodeInclusionByStandardSize,
} from './model/inclusionModel'

import { dependencyExpandModel } from './model/dependencyExpandModel'
import {
  CountIconWrap,
  DynamicModeIconButton,
  DynamicModeText,
  DynamicModeWrapper,
  DynamicTooltipBody,
  DynamicTooltipHeader,
  DynamicTooltipWrapper,
  EditorPanelHeader,
  EditorPanelHeaderIcon,
  EditorPanelHeaderTitle,
  BrandContainer,
  BrandTitle,
  BrandSecondaryWrapper,
  ImportButton,
} from './EstimationEditorPage.styled'
import { NotActual } from './EstimationReport/Report/NotActual'
import { searchSourceModel } from './model/searchSourceModel'
import { expandModel } from './EditorTreeWrap/expandModel'
import { dynamicFlatItemsInitialFetchService } from './model/current/dynamicFlatItemsFetch.init'
import { truncateText } from './truncateText'
import { dynamicGroupConditions$ } from './model/dynamic-conditions.store'
import { currentProject$ } from './model/current/project'
import {
  createPendingVersionByDatesKey,
  pendingMapVersionByDates$,
} from './model/pendingMapVersionByDates'

import { filterPanelService } from './model/filterPanelService'
import {
  SelectSourcePanel,
  selectedSourceNodes$,
} from './model/selectSourcePanel.model'
import { searchModel, Tags } from './model/current/searchModel'
import { viewerDerivatives$ } from './model/current/fetchViewerModelDerivatives'
import {
  containerMounted,
  currentEstimationStatus$,
  previewResult$,
  resetEstimationStatus,
  resetPreviewResult,
} from './model/reportService'
import { isGroupItem, reportPending$ } from './model/reportDiffService'
import { EstimationReport } from './EstimationReport/EstimationReport'
import { diffService } from './model/diffService'

enum TreeSections {
  viewer,
  dependencies,
  tree,
  formula,
  results,
}

// TODO разобрать конструкторы и сторы до объявления компонента по файлам
const versionHistoryPending$ = combine(
  [
    api.Estimation.fetchNamedVersions.defaultContext.pending$,
    api.Estimation.fetchVersionDates.defaultContext.pending$,
    // smApi.Estimation.fetchVersionByDate.defaultContext.pending$,
  ],
  pendings => pendings.some(Boolean),
)

const copyFolderPending$ = combine(
  [
    smApi.UserClassifierGroup.groupCopying.defaultContext.pending$,
    smApi.UserClassifier.fetch.defaultContext.pending$,
  ],
  pendings => pendings.some(Boolean),
)

const statusPending$ = api.Estimation.fetchStatus.defaultContext.pending$

const { resetBimFileIds } = createBimFileSubscriptions({
  subscribe: notificationService.subscriptions.subscribeBimFile,
  unsubscribe: notificationService.subscriptions.unsubscribeBimFile,
})

//FSK-655/29

merge([
  smApi.BimReference.remove.doneData,
  smApi.BimReference.create.doneData,
  smApi.BimReference.move.doneData,
  smApi.UserClassifierGroup.create.doneData,
  smApi.UserClassifierGroup.move.doneData,
  smApi.UserClassifierGroup.rename.doneData,
  smApi.UserClassifierGroup.remove.doneData,
  smApi.UserClassifier.removeAll.doneData.map(({ id }) => ({
    parentClassifierId: id,
  })),
]).watch(({ parentClassifierId: id }) => {
  smApi.UserClassifier.getMostRecent.defaultContext.submit({ id })
})

const inclusionStatusWithTranscript = {
  PARENT_INCLUDED: 'Родительский элемент добавлен в правило',
  SELF_INCLUDED: 'Элемент добавлен в правило',
  CHILDREN_INCLUDED: 'Один из дочерних элементов добавлен в правило',
}

const selectedModels$ = selectedModelsService.models$.map(
  models => models?.map(({ viewerId }) => viewerId) || [],
)

const modelStoreService = createModelStoreService((node, viewerRef) =>
  getViewerId({
    node,
    viewerRef,
    nodes: classifierService.nodes$.getState(),
    getNodeFunc: getNode,
  }),
)

sample({
  source: estimationService.estimation.currentEstimation$,
  clock: merge([
    api.Estimation.renameVersion.doneData,
    api.Estimation.removeVersionName.doneData,
  ]),
  fn: (estimation, versionData) => ({ estimation, versionData }),
}).watch(({ estimation, versionData }) => {
  if (estimation?.version === versionData.version) {
    api.Estimation.fetch.defaultContext(estimation)
  }
})

const { subscriptions, message } = notificationService

const notification = message.filter({ fn: smApi.NotificationEvent.is })

const { versionDates$, versions$, removeVersion } = createVersionHistoryStore({
  fetchDates: api.Estimation.fetchVersionDates.doneData,
  fetchVersions: api.Estimation.fetchVersionByDate.done.map(
    ({ params, result: { versions } }) => ({
      versionDate: params.versionDate,
      versions,
    }),
  ),
  fetchNamedVersionDates: api.Estimation.fetchNamedVersions.doneData.map(
    ({ versions }) => ({
      versionDates: [
        ...new Set(
          versions.map(version => fromDateTimeToDate(version.createdDate)),
        ),
      ],
    }),
  ),
  fetchNamedVersions: api.Estimation.fetchNamedVersions.doneData.map(
    ({ versions }) =>
      versions.map(version => ({
        versionDate: fromDateTimeToDate(version.createdDate),
        versions: versions
          .filter(vrs => compareDates(vrs.createdDate, version.createdDate))
          .sort((a, b) => sortByDate(a.createdDate, b.createdDate)),
      })),
  ),
  versionNameChanged: merge([
    api.Estimation.renameVersion.doneData,
    api.Estimation.removeVersionName.doneData,
  ]).map(data => ({
    versionDate: fromDateTimeToDate(data.createdDate),
    version: data,
  })),
  changeStatusVersion: api.Estimation.changeStatus.defaultContext.doneData.map(
    data => ({
      versionDate: fromDateTimeToDate(data.estimationVersionData.createdDate),
      version: data.estimationVersionData,
    }),
  ),
})

const { addedDependencyIds$, currentModelsByClassifierMap$ } =
  createSelectDependenciesParams({
    nodes$: classifierService.nodes$,
    currentEntity$: currentUserClassifier$,
  })

const seoEventHandlerDependency = (
  id: number,
  type: string,
  action?: 'add' | 'remove',
) => {
  const currentEstimation =
    estimationService.estimation.currentEstimation$.getState()

  let eventName:
    | 'Gtech_Estimation_EstimationDependencyAdd'
    | 'Gtech_Estimation_EstimationDependencyDelete' =
    'Gtech_Estimation_EstimationDependencyAdd'

  if (action === 'remove') {
    eventName = 'Gtech_Estimation_EstimationDependencyDelete'
  }

  seoEventManager.push({
    event: eventName,
    payload: {
      estimationId: currentEstimation?.id,
      dependencyId: id,
      dependencyType: type,
    },
  })
}

const { ModelsTree, bimFile$ } = createModelsTree({
  addedDependencyIds$,
  inclusionStatus$: inclusionStore$,
  currentModelsByClassifierMap$,
  currentEntity$: currentUserClassifier$,
  notification,
  seoEventHandler: seoEventHandlerDependency,
})

const userClassifierTree$ = createUserClassifierRepoTreeModel(
  userClassifierRepoService,
  projectUrn$,
)

const { ClassifiersTree } = createClassifiersTree({
  inclusionStatus$: inclusionStore$,
  addedDependencyIds$,
  tree$: userClassifierTree$,
  currentUserClassifier$,
  seoEventHandler: seoEventHandlerDependency,
})

const { ModelManageMenu } = createModelManageMenu({
  classifierService,
  currentEntity$: currentUserClassifier$,
  bimFile$,
  seoEventHandler: () => {
    seoEventManager.push({
      event: 'Gtech_Estimation_EstimationBell',
      payload: {},
    })
  },
})

const estimationStarted = currentEstimationStatus$.updates.filter({
  fn: value => value === 'Started',
})

export const expandVersionModel = createExpandModel()

const setDependencyRootNodes = createEvent<Node[]>()
const dependencyRootNodes$ = restore(setDependencyRootNodes, [])

dependencyTreeModel.flatTree$.updates.watch(flatTree => {
  // TODO разобраться почему нет апдейтов от стора dependencyTreeModel.flatTree$ в подписке clock(sample)
  // Информация будет всегда актуальной так как flatTree обновится после апдейта nodes$
  // Сделано во избежания конфликта со стором tree$

  const nodes = classifierService.nodes$.getState()

  setDependencyRootNodes(
    flatTree
      .filter(isApiFlatNode)
      .filter(({ path }) => path.length === 1)
      .map(({ ref }) => getNode(nodes, ref))
      .filter(isNotEmpty),
  )
})

const parentData$ = sample({
  clock: dependencyRootNodes$,
  source: classifierService.nodes$,
  fn: (nodes, nodeArr) =>
    nodeArr
      .map(node => {
        if (isReferenceNode(node) && node.type === 'BimModelReferenceNode') {
          const model = getNode(nodes, node.element)
          if (!model) {
            return null
          }
          return { id: node.parentClassifierId, name: model.name }
        } else if (isAssemblyClassifierNode(node) && node.assemblyModelRef) {
          const model = getNode(nodes, node.assemblyModelRef.element)
          if (!model) {
            return null
          }
          return { id: node.id, name: model.name }
        } else if (isUserClassifierNode(node)) {
          return { id: node.id, name: node.name }
        }

        return null
      })
      .filter(isNotEmpty),
})

// Инкапсулировать логику в common после GT-984 - Front: Исправить проблему: "circular dependencies" в репозиториях
const modelUpdated = notificationService.message
  .filter({ fn: smApi.NotificationEvent.Update.is })
  .map(prop('payload'))
  .filter({ fn: smApi.BimModel.is })
  .filter({
    fn: model =>
      model.modelType === 'ForgeModel' && model.status === 'ImportCompleted',
  })

guard({
  clock: modelUpdated,
  source: currentUserClassifier$.map(cls =>
    cls ? { classifierId: cls.id, classifierVersion: cls.version } : null,
  ),
  filter: Boolean,
  target: smApi.UserClassifier.fetchFlatListDependencies.defaultContext.submit,
})

export const filteredDependencyTree$ = searchModel.filterTree(filteredFlatTree$)

export const { StatusPanel: DependenciesStatusPanel } = createStatusPanel({
  checkedModel: dependencyCheckedModel,
  flatTree$: dependencyTreeModel.flatTree$,
  searchNodesInfo$: searchModel.searchNodes$,
  SelectSourcePanel,
  hideIsolateButton$: getFilterIsActiveStore(searchModel.searchNodes$),
  openedFilterPanel$: filterPanelService.openedFilterPanel$,
  selectedSourceNodes$,
})

export const { StatusPanel: EditorTreeStatusPanel } = createStatusPanel({
  checkedModel: editorCheckedModel,
  flatTree$: treeModel.flatTree$,
  searchNodesInfo$: searchModel.searchNodes$,
  openedFilterPanel$: filterPanelService.openedFilterPanel$,
})

createViewerDisplayModel({
  currentEntity$: currentUserClassifier$,
  nodes$: classifierService.nodes$,
  searchModel,
  getViewerId: (node, viewerRef) =>
    getViewerId({
      node,
      viewerRef,
      nodes: classifierService.nodes$.getState(),
      getNodeFunc: getNode,
    }),
  getClsModels: (clsId, clsVersion) => {
    const dependenciesWithModels =
      modelStoreService.dependenciesWithModels$.getState()
    const models =
      dependenciesWithModels
        .find(
          ({ classifier }) =>
            classifier.id === clsId && classifier.version === clsVersion,
        )
        ?.models.map(m => adapter(m)) || null

    if (!models) {
      throw new Error(
        `Models not found in classifier id: ${clsId}, version: ${clsVersion}`,
      )
    }

    // Now we are sure that model arr contain  only bim360 or Gstation models
    validateModelTypes(models)

    return models
  },
})

const searchedBimNodes$ = sample({
  source: classifierService.nodes$,
  clock: searchModel.searchNodes$,
  fn: (nodes, search) => {
    if (!search?.nodes?.length) {
      return null
    }
    return search.nodes
      .map(({ node }) => getBimNode(node, nodes))
      .filter(isNotEmpty)
  },
}).reset(searchModel.resetSearchNodes)

forward({
  from: smApi.UserClassifier.fetchDynamicGroupFlatItems.trigger,
  to: searchModel.fetchParentDynamicGroups,
})

dynamicFlatItemsInitialFetchService(
  currentUserClassifier$,
  classifierService.reset,
)

const lockStatusMessage = message.filter({
  fn: smApi.NotificationEvent.LockStatusChange.is,
})

const { lockMap$ } = createLockModel({ message: lockStatusMessage })
export type RenderEstimationReportProps = {
  setSelectViewerRefs: React.Dispatch<
    React.SetStateAction<Record<string, string[]>>
  >
  widthResultsResizableCol: number
  currentEstimation: EstimationEntity
  conditionChanged: boolean
  dynamicGroupPending: boolean
  groupingProcessStatus: smApi.DynamicGroupStatus.Data['status'] | null | null
  estimationStatus: api.Estimation.Status | null
  reportPending: boolean
  notActualResult: boolean
  nonStartedEstimation: boolean
}

export const EstimationEditorPage = memo(
  ({
    currentEstimation,
    isCurrentVersion,
    renderEstimationReport,
  }: {
    currentEstimation: EstimationEntity
    isCurrentVersion: boolean
    renderEstimationReport?: (props: RenderEstimationReportProps) => ReactNode
  }) => {
    const userInfo = useStore(userInfo$)
    const copiedFolderId = useStore(copiedFolder$)
    const history = useHistory()
    const versionHistoryPending = useStore(versionHistoryPending$)
    const conditionChanged = useStore(conditionChanged$)
    const estimationStatus = useStore(currentEstimationStatus$)
    const reportPending = useStore(reportPending$)
    const statusPending = useStore(statusPending$)
    const selectedModels = useStore(selectedModels$)
    const currentUserClassifier = useStore(currentUserClassifier$)
    const groupingProcessStatus = useStore(groupingProcessStatus$)
    const inCreateNode = useStore(treeModel.inCreateNode$)
    const currentProject = useStore(currentProject$)
    const viewerDerivatives = useStore(viewerDerivatives$)
    const migratePending = useStore(smApi.migrate.pending$)
    const isLock = useStoreMap({
      store: lockMap$,
      keys: [currentEstimation?.classifierId],
      fn: (state, [classifierId]) => !!state[classifierId],
    })
    const copyFolderPending = useStore(copyFolderPending$)

    const fetchFlatListDependenciesPending = useStore(
      smApi.UserClassifier.fetchFlatListDependencies.defaultContext.pending$,
    )
    const fetchFlatListItemsPending = useStore(
      smApi.UserClassifier.fetchFlatListItems.defaultContext.pending$,
    )
    const renameEstimationPending = useStore(
      api.Estimation.rename.defaultContext.pending$,
    )
    const changeStatusPending = useStore(
      api.Estimation.changeStatus.defaultContext.pending$,
    )

    const fetchListProjectPending = useStore(
      smApi.Project.fetchList.defaultContext.pending$,
    )
    const pendingMapVersionByDates = useStore(pendingMapVersionByDates$)

    const [selectViewerRefs, setSelectViewerRefs] = React.useState<
      Record<string, string[]>
    >({})
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    React.useEffect(() => {
      const subscription =
        api.Estimation.changeStatus.defaultContext.doneData.watch(data => {
          enqueueSnackbar('', {
            // eslint-disable-next-line react/display-name
            content: key => (
              <Snackbar
                id={key}
                message={`Версия "${
                  data.estimationVersionData.name ||
                  `V00${data.estimationVersionData.version}`
                }" опубликована`}
                onClose={() => closeSnackbar(key)}
              />
            ),
            variant: 'info',
          })
        })

      return () => subscription.unsubscribe()
    }, [closeSnackbar, enqueueSnackbar])

    React.useEffect(
      () => () => modelStoreService.resetDependenciesWithModels(),
      [],
    )

    React.useEffect(() => {
      const subscription = searchModel.resetSearchNodes.watch(() => {
        setSelectViewerRefs({})
      })

      return () => {
        subscription.unsubscribe()
      }
    }, [])

    const { keycloak } = useKeycloak()

    const versionDates = useStore(versionDates$)
    const versions = useStore(versions$)

    const [openedResultCalculating, setOpenedResultCalculating] =
      React.useState(false) //TODO Костыль для того, чтобы корректно совмещались ноды в дереве с нодами на вкладке "Результат расчёта" https://gmini.atlassian.net/browse/GS-335?focusedCommentId=10544

    const { InclusionFilterButton, InclusionFilterContent } =
      useInclusionFilter({
        seoEventHandler: (type: string) => {
          seoEventManager.push({
            event: 'Gtech_Estimation_EstimationDependencyFilter',
            payload: {
              estimationId: currentEstimation?.id,
              filterType: type,
            },
          })
        },
      })

    const selectedGroup = useSelectedGroupNode({
      nodes$: classifierService.nodes$,
    })

    const { dynamicGroupMode, toggleDynamicMode, resetDynamicGroupMode } =
      useDynamicGroupMode({
        seoEventHandler: () => {
          seoEventManager.push({
            event: 'Gtech_Estimation_EstimationGroupMode',
            payload: {
              estimationId: currentEstimation?.id,
            },
          })
        },
      })

    const estimationId = currentEstimation?.id
    const classifierId = currentEstimation?.classifierId

    useEffect(() => {
      if (estimationId && classifierId) {
        subscriptions.subscribeEstimation({ estimationId, classifierId })
      }

      return () => {
        if (estimationId && classifierId) {
          subscriptions.unsubscribeEstimation({
            estimationId,
            classifierId,
          })
        }
      }
    }, [estimationId, classifierId])

    const onToggleDynamicMode = React.useCallback(() => {
      if (currentEstimation) {
        setCurrentGroup(null)
        toggleDynamicMode()
        expandModel.resetExpanded()
        editorCheckedModel.resetChecked()
      }
    }, [currentEstimation, toggleDynamicMode])

    const createRootGroup = useCallback(() => {
      treeModel.setInCreateRootNode()
    }, [])

    const pasteGroupInRoot = React.useCallback(() => {
      if (currentUserClassifier && copiedFolderId) {
        smApi.UserClassifierGroup.groupCopying.defaultContext.submit({
          classifierId: currentUserClassifier.id,
          classifierVersion: currentUserClassifier.version,
          parentGroupId: null,
          sourceGroupId: copiedFolderId,
        })
      }
    }, [currentUserClassifier, copiedFolderId])

    useEffect(
      () => () => {
        estimationService.estimation.resetCurrent()
        setCurrentGroup(null)
        resetDynamicGroupMode()
        classifierService.reset()
        resetBimFileIds()
        resetPreviewResult()
        resetEstimationStatus()
        resetGroupingStatus()
        resetCopyFolder()
      },
      [resetDynamicGroupMode],
    )

    useEffect(() => {
      filter.setStorageKey(
        currentEstimation
          ? `estimationDependencyFilter:${currentEstimation.id}`
          : null,
      )

      return filter.reset
    }, [currentEstimation])

    // TODO Рефакторинг логики перезапроса статусов динамических группировок
    useEffect(() => {
      if (!currentUserClassifier) {
        return
      }
      checkStatusGrouping({
        classifierId: currentUserClassifier.id,
        version: currentUserClassifier.version,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dynamicGroupMode])

    // TODO Рефакторинг логики перезапроса статусов динамических группировок
    useEffect(() => {
      const statusSubscribe = smApi.DynamicGroup.status.doneData.watch(
        params => {
          if (params.status === 'ErrorTimedOut') {
            return
          }

          if (params.status === 'NotStarted' && currentUserClassifier) {
            startGrouping({
              classifierId: currentUserClassifier.id,
              version: currentUserClassifier.version,
            })
          }

          if (
            params.status === 'Finished' &&
            currentUserClassifier &&
            dynamicGroupMode
          ) {
            smApi.UserClassifier.fetchDynamicGroupFlatItems.defaultContext({
              classifierId: currentUserClassifier.id,
              classifierVersion: currentUserClassifier.version,
            })
          }
        },
      )

      return () => statusSubscribe.unsubscribe()
    }, [classifierId, currentUserClassifier, dynamicGroupMode])

    useEffect(() => {
      if (currentUserClassifier?.id && currentUserClassifier?.version) {
        smApi.DependencyWithModels.getClassifierDependencyModels.defaultContext(
          {
            id: currentUserClassifier.id,
            version: currentUserClassifier.version,
          },
        )
      }
    }, [currentUserClassifier?.id, currentUserClassifier?.version])

    const { readonlyMode } = useReadonlyMode()

    const [openVersionHistory, setOpenVersionHistory] = React.useState(false)

    const { NamedVersionsSwitch, namedVersions, closeNamedVersions } =
      useNamedVersions({
        getNamedVersions: async () =>
          !!(await api.Estimation.fetchNamedVersions.defaultContext({
            estimationId: currentEstimation.id,
          })),
        onDisable: () => {
          expandVersionModel.resetExpanded()
          api.Estimation.fetchVersionDates.defaultContext({
            estimationId: currentEstimation.id,
          })
        },
        onActive: () =>
          versionDates &&
          expandVersionModel.expandAll(versionDates.versionDates),
        disabled: versionHistoryPending,
      })

    const onOpenVersionHistory = React.useCallback(async () => {
      await api.Estimation.fetchVersionDates.defaultContext({
        estimationId: currentEstimation.id,
      })

      setOpenVersionHistory(true)
    }, [currentEstimation])

    const { ChangeVersionNameDialog, setChangeNameDialog, changeNameDialog } =
      useVersionName<smApi.VersionData>({
        onSubmitChangeName: async ({ name, version }) =>
          !!(await api.Estimation.renameVersion.defaultContext({
            estimationId: currentEstimation.id,
            estimationVersion: version,
            versionName: name,
          })),
      })

    const goToVersion = (id: number, version: number) => {
      window.open(
        `${window.location.origin}/estimation/${id}/version/${version}`,
        '_blank',
      )
    }

    const { ContextMenu, setCtxMenu, ctxMenu } =
      useContextMenu<smApi.VersionData>([
        {
          title: 'Перейти к версии',
          icon: Icon.RECOVERY,
          onClick: ({ version }) => goToVersion(currentEstimation!.id, version),
          show: item => item.version !== currentEstimation.version,
        },
        {
          title: 'Переименовать',
          icon: Icon.EDIT,
          onClick: item => {
            setChangeNameDialog(item)
          },
          show: item => !!item.name,
        },
        {
          title: 'Присвоить имя',
          icon: Icon.EDIT,
          onClick: item => {
            setChangeNameDialog(item)
          },
          show: item => !item.name,
        },
        {
          title: 'Удалить название',
          icon: Icon.DELETE,
          onClick: item => {
            api.Estimation.removeVersionName
              .defaultContext({
                estimationId: currentEstimation.id,
                estimationVersion: item.version,
              })
              .then(version => {
                if (namedVersions) {
                  removeVersion({
                    versionDate: fromDateTimeToDate(version.createdDate),
                    version,
                  })
                }
              })
          },
          show: item => !!item.name,
        },
      ])

    const editorChecked = useStore(editorCheckedModel.checked$)
    const dependencyChecked = useStore(dependencyCheckedModel.checked$)
    const dynamicGroupPending = useStore(dynamicGroupPending$)

    const onCloseVersionHistory = React.useCallback(() => {
      if (namedVersions) {
        closeNamedVersions()
      }
      setOpenVersionHistory(false)
    }, [closeNamedVersions, namedVersions])

    const updatedEvent = notificationService.message
      .filter({ fn: api.NotificationEvent.Update.is })
      .map(prop('payload'))
      .filter({ fn: api.Estimation.is })

    const onCompareCallback = React.useCallback(
      (
        successCallback: (
          estimationId: number,
          startVersion: number,
          endVersion: number,
        ) => void,
        errorCallback: (oldVersion: number) => void,
      ) => {
        createOneTimeSubscription({
          event: updatedEvent,
          callback: async state => {
            if (!state) {
              return
            }

            await Promise.all([
              api.Estimation.start.defaultContext({
                estimationId: currentEstimation.id,
                estimationVersion: currentEstimation.version,
              }),
              api.Estimation.start.defaultContext({
                estimationId: state.id,
                estimationVersion: state.version,
              }),
            ])

            const startFinished = {
              [currentEstimation.id]: false,
              [state.id]: false,
            }

            const fetchAndCheckStatusEstimation = async () => {
              const subscription =
                api.Estimation.fetchStatus.defaultContext.doneData.watch(
                  estimation => {
                    if (estimation.status === 'Finished') {
                      startFinished[estimation.id] = true
                    }

                    if (
                      !startFinished[currentEstimation.id] ||
                      !startFinished[state.id]
                    ) {
                      setTimeout(async () => {
                        await fetchAndCheckStatusEstimation()
                        subscription.unsubscribe()
                      }, 15000)
                    }
                  },
                )

              await Promise.all([
                api.Estimation.fetchStatus.defaultContext.submit({
                  estimationId: currentEstimation.id,
                  estimationVersion: currentEstimation.version,
                }),
                api.Estimation.fetchStatus.defaultContext.submit({
                  estimationId: state.id,
                  estimationVersion: state.version,
                }),
              ])
            }

            await fetchAndCheckStatusEstimation()

            const errors =
              api.Estimation.start.defaultContext.errors$.getState()
            const key1 = api.Estimation.start.getKey({
              estimationId: currentEstimation.id,
              estimationVersion: currentEstimation.version,
            })
            const key2 = api.Estimation.start.getKey({
              estimationId: state.id,
              estimationVersion: state.version,
            })

            if (errors[key1] || errors[key2]) {
              errorCallback(currentEstimation.version)
              return
            }

            successCallback(
              currentEstimation.id,
              currentEstimation.version,
              state.version,
            )
          },
        })
      },
      [currentEstimation.id, currentEstimation.version, updatedEvent],
    )

    const [panelsDebouncedState, setPanelsDebouncedState] = React.useState<
      ResizableColumnStateItem[]
    >([])

    const onColumnsWidthsChanged = React.useCallback(
      (state: ResizableColumnStateItem[]) => {
        setPanelsDebouncedState(state)
      },
      [],
    )

    const onErrorGetViewerModelUrl = (errorText: string) => {
      enqueueSnackbar(errorText, {
        variant: 'error',
      })

      seoEventManager.push({
        event: 'Gtech_Estimation_Error',
        payload: {
          estimationId: currentEstimation?.id,
          errorType: 'red',
          errorText,
        },
      })

      selectedModelsService.reset()
    }

    const widthFormulaResizableCol =
      panelsDebouncedState[TreeSections.formula]?.widthPx || 0

    const widthColMoreMin = widthFormulaResizableCol > 310

    const widthTreeResizableCol =
      panelsDebouncedState[TreeSections.tree]?.widthPx || 0

    const widthResultsResizableCol =
      panelsDebouncedState[TreeSections.results]?.widthPx || 0

    const notActualResult =
      conditionChanged || estimationStatus !== 'Finished' || statusPending

    const resizableRef = React.useRef<ResizableRefApi>(null)

    const dynamicGroupConditions = useStore(dynamicGroupConditions$)

    const onRenameEntity = (value: string) => {
      api.Estimation.rename.defaultContext.submit({
        name: value,
        parentFolderId: currentEstimation.parentFolderId,
        id: currentEstimation.id,
        version: currentEstimation.version,
      })
    }

    //Открываем панель результатов(если закрыта) когда расчет запустился
    React.useEffect(() => {
      const subscription = estimationStarted.watch(() => {
        if (resizableRef.current) {
          resizableRef.current.openPanel(TreeSections.results)
        }
      })
      return () => {
        subscription.unsubscribe()
      }
    }, [])

    const { NavbarDropDown, opened } = useNavbar({
      navModules: getModulesLinkFromEnv(
        envLinks,
        currentProject?.urn,
        currentProject?.sourceType,
      ),
      seoEventHandler: (selectedExplorer: string) => {
        seoEventManager.push({
          event: 'ExplorersNavigation_SelectExplorer',
          payload: {
            originalExplorer: 'Объемы',
            selectedExplorer,
          },
        })
      },
    })

    const projectBadge = (
      <ProjectBadge
        projectName={currentProject?.name || ''}
        loading={fetchListProjectPending}
      />
    )

    const currentEstimationVersionStatus: smApi.VersionStatus = useMemo(
      () =>
        currentEstimation?.estimationPublishedVersions?.find(
          publishedVersion =>
            publishedVersion.classifierId === currentUserClassifier?.id &&
            publishedVersion.classifierVersion ===
              currentUserClassifier?.version,
        )?.estimationVersionData.estimationVersionStatus || 'DRAFT',
      [
        currentEstimation.estimationPublishedVersions,
        currentUserClassifier?.id,
        currentUserClassifier?.version,
      ],
    )

    const lastPublishedVersionData = useMemo(
      () =>
        currentEstimation.estimationPublishedVersions?.reduce(
          (acc: api.Estimation.VersionWithClassifierData | null, item) => {
            if (!acc || acc.classifierVersion < item.classifierVersion) {
              // eslint-disable-next-line no-param-reassign
              acc = { ...item }
            }

            return acc
          },
          null,
        ),
      [currentEstimation.estimationPublishedVersions],
    )

    const isPublishedVersion = useCallback(
      version => version.estimationVersionStatus === 'PUBLISHED',
      [],
    )

    const nonStartedEstimation =
      !statusPending && estimationStatus !== 'Finished'

    if (!currentUserClassifier) {
      return null
    }

    if (!currentProject) {
      return null
    }

    return (
      <>
        <DragLayer
          editorCheckedCount={Object.keys(editorChecked).length}
          depsCheckedCount={Object.keys(dependencyChecked).length}
        />
        <Box height='100%' display='flex' flexDirection='column'>
          <CurrentEstimation />
          <Header
            title={currentEstimation?.name}
            brandSecondary={
              <BrandSecondaryWrapper>{projectBadge}</BrandSecondaryWrapper>
            }
            titleSecondary={
              <>
                <VersionButton
                  onClick={onOpenVersionHistory}
                  isActualVersion={isCurrentVersion}
                  currentVersion={currentEstimation.version}
                  versionCreated={currentEstimation.versionCreatedDate}
                  versionName={currentEstimation.versionName}
                  pending={versionHistoryPending}
                  title={currentEstimation.name}
                  onRename={onRenameEntity}
                  renamePending={renameEstimationPending}
                  renderVersionBrand={(
                    props: RenderVersionButtonBrandProps,
                  ) => (
                    <>
                      {currentEstimationVersionStatus !== 'PUBLISHED' && (
                        <VersionStatusSwitch
                          {...props}
                          currentStatus={currentEstimationVersionStatus}
                          onChangeStatus={(status: VersionStatus) => {
                            api.Estimation.changeStatus.defaultContext({
                              estimationId: currentEstimation.id,
                              estimationVersion: currentEstimation.version,
                              estimationVersionStatus: status,
                            })
                          }}
                          disabled={nonStartedEstimation}
                          loading={changeStatusPending}
                        />
                      )}

                      {currentEstimationVersionStatus === 'PUBLISHED' && (
                        <VersionStatusInfo
                          {...props}
                          versionCreated={currentEstimation.versionCreatedDate}
                          versionAuthor={currentEstimation.versionNameOwner}
                        />
                      )}
                      {currentEstimationVersionStatus === 'DRAFT' &&
                        lastPublishedVersionData && (
                          <VersionStatusInfo
                            currentVersion={
                              lastPublishedVersionData.estimationVersionData
                                .version
                            }
                            versionCreated={
                              lastPublishedVersionData.estimationVersionData
                                .createdDate
                            }
                            versionAuthor={
                              lastPublishedVersionData.estimationVersionData
                                .nameOwner
                            }
                            versionName={
                              lastPublishedVersionData.estimationVersionData
                                .name
                            }
                            isActualVersion={isCurrentVersion}
                            titleTooltip='Перейти к опубликованной версии'
                            onClick={() =>
                              goToVersion(
                                currentEstimation.id,
                                lastPublishedVersionData.classifierVersion,
                              )
                            }
                            clickable
                          />
                        )}
                    </>
                  )}
                />

                <Box ml={1} flexGrow={1}>
                  <Tags
                    dataTestIdFor={{
                      root: 'findAnywhereBadge',
                      deleteIcon: 'resetFilter',
                    }}
                  />
                </Box>
                <filterPanelService.FilterButton
                  searchModel={searchModel}
                  currentUserClassifier={currentUserClassifier}
                />
              </>
            }
            onLogout={() => keycloak.logout()}
            brand={
              <Tooltip
                enterDelay={400}
                title={opened ? '' : 'Выйти к списку инспекций'}
              >
                <BrandContainer
                  data-test-id='backToExplorer'
                  to={
                    currentProject ? `/?projectUrn=${currentProject?.urn}` : '/'
                  }
                  active={opened}
                >
                  <HeaderLogoWrap>
                    <Logo />
                  </HeaderLogoWrap>
                  <BrandTitle>Объёмы</BrandTitle>
                  {NavbarDropDown}
                </BrandContainer>
              </Tooltip>
            }
            userInfo={userInfo}
            onProfileClick={() => goals.showUserMenu()}
          />

          <PageContentContainer data-test-id='EstimateEditor'>
            <Resizable
              foldedPanelWidthPx={50}
              onWidthChangedDelay={50}
              ref={resizableRef}
              storageKey='EstimationEditorColumns'
              onWidthsChanged={onColumnsWidthsChanged}
            >
              <ResizablePanel
                header={
                  <Box
                    ml='-16px'
                    maxWidth='calc(100% - 32px)'
                    style={{ overflow: 'hidden' }}
                  >
                    <ModelSelect
                      dependencyRootNodes$={dependencyRootNodes$}
                      currentEntity$={currentUserClassifier$}
                      searchedBimNodes$={searchedBimNodes$}
                      modelStoreService={modelStoreService}
                      getViewerId={(node, viewerRef) =>
                        getViewerId({
                          node,
                          viewerRef,
                          nodes: classifierService.nodes$.getState(),
                          getNodeFunc: getNode,
                        })
                      }
                    />
                  </Box>
                }
                foldedTitle='Просмотр модели'
                onOpened={() => {
                  goals.expandViewer()
                  // logEventEditor('EXPAND_VIEWER', { entity: 'Estimation' })
                }}
                onClosed={() => {
                  goals.foldViewer()
                  // logEventEditor('FOLD_VIEWER', { entity: 'Estimation' })
                }}
                data-test-id='ModelViewerSection'
                bodyStyles={{ borderRight: '' }}
                hideCssOnlyVH
              >
                <forgeViewer.ViewerComponent
                  selectedModels={selectedModels}
                  resetSearchNodes={searchModel.resetSearchNodes}
                  selectViewerElements={selectViewerRefs}
                  resetSelectedModels={selectedModelsService.reset}
                  getModelUrl={modelUrn =>
                    getViewerModelUrl(
                      modelUrn,
                      viewerDerivatives,
                      onErrorGetViewerModelUrl,
                    )
                  }
                  fetchToken={() => fetchViewerToken(keycloak)}
                  loadModelByUrl
                />
              </ResizablePanel>

              <ResizablePanel
                title='Зависимости'
                header={
                  <SeparatorList>
                    <Tooltip placement='top' title='Добавить модель(и)'>
                      <IconButton
                        data-test-id='openDependencyList'
                        data-test-ui-type='CreateButton'
                        disabled={readonlyMode.enabled}
                        onClick={openExplorer}
                      >
                        <PlusCircle color='rgba(53, 59, 96, 0.5)' />
                      </IconButton>
                    </Tooltip>

                    {InclusionFilterButton}
                  </SeparatorList>
                }
                headerSecondary={
                  <ModelManageMenu
                    disabled={migratePending || isLock}
                    onCompareCallback={onCompareCallback}
                  />
                }
                footer={<DependenciesStatusPanel />}
                onOpened={() => {
                  goals.expandDependencies()
                }}
                onClosed={() => {
                  goals.foldDependencies()
                }}
                data-test-id='DependenciesSection'
                body={InclusionFilterContent}
                bodyStyles={{ borderRight: '' }}
                hideCssOnlyDN
              >
                <Box
                  width='100%'
                  height='100%'
                  display='flex'
                  flexDirection='column'
                >
                  <TreeLoader
                    loading={
                      fetchFlatListDependenciesPending ||
                      migratePending ||
                      isLock
                    }
                    text='Идет загрузка...'
                  />

                  <Cover
                    open={
                      currentUserClassifier?.sourceClassifiers?.length === 0 &&
                      !fetchFlatListDependenciesPending
                    }
                    icon={
                      <Substrate>
                        <WithCursorIcon>
                          <PlusCircle color='#DADADA' width={45} height={45} />
                        </WithCursorIcon>
                      </Substrate>
                    }
                    title={
                      <>
                        Добавьте Модели в “Зависимости”
                        <br /> для начала работы
                      </>
                    }
                  />

                  <DependencyTreeWrap
                    dynamicGroupsConditions={dynamicGroupConditions}
                    currentUserClassifier$={currentUserClassifier$}
                    dependencyCheckedModel={dependencyCheckedModel}
                    dependencyExpandModel={dependencyExpandModel}
                    dependencyTreeModel={dependencyTreeModel}
                    loadNodeInclusion={loadNodeInclusion}
                    filteredFlatTree$={filteredDependencyTree$}
                    loadNodeInclusionByStandardSize={
                      loadNodeInclusionByStandardSize
                    }
                    nodes$={classifierService.nodes$}
                    searchModel={searchModel}
                    inclusionStatusWithTranscript={
                      inclusionStatusWithTranscript
                    }
                    inclusionStore$={inclusionStore$}
                    sourceClassifiersLoaded$={sourceClassifiersLoaded$}
                    searchSourceData$={searchSourceModel.searchSourceData$}
                    selectForgeRefs={setSelectViewerRefs}
                    sources={filterPanelService.sources}
                    selectedSourceNodes$={selectedSourceNodes$}
                    openedFilterPanel$={filterPanelService.openedFilterPanel$}
                    seoEventHandler={seoEventHandlerDependency}
                    diffService={diffService}
                  />
                  <SelectDependencies
                    ClassifiersTree={ClassifiersTree}
                    ModelsTree={ModelsTree}
                    brand={projectBadge}
                  />
                </Box>
              </ResizablePanel>

              <ResizablePanel
                title='Мои расчёты'
                header={
                  <SeparatorList>
                    <Tooltip
                      placement='top'
                      title='Добавить папку в Мои расчёты'
                    >
                      <IconButton
                        onClick={() => createRootGroup()}
                        data-test-id='addRootGroup'
                        data-test-ui-type='CreateButton'
                        disabled={readonlyMode.enabled}
                      >
                        <FolderWithPlus color='rgba(53, 59, 96, 0.5)' />
                      </IconButton>
                    </Tooltip>
                    {copiedFolderId && (
                      <Tooltip
                        placement='top'
                        title='Вставить скопированную папку в корень'
                      >
                        <IconButton
                          onClick={() => pasteGroupInRoot()}
                          data-test-ui-type='CreateButton'
                        >
                          <SuccessfulContentPaste color='rgba(53, 59, 96, 0.5)' />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip placement='top' title='Импорт структуры расчёта'>
                      <ImportButton
                        onClick={() => {
                          seoEventManager.push({
                            event:
                              'Gtech_Estimation_ImportTemplateStructure_Start',
                            payload: {
                              estimationId: currentEstimation.id,
                            },
                          })

                          importEstimationService.open()
                        }}
                        data-test-ui-type='ImportEstimationButton'
                      >
                        <Import />
                      </ImportButton>
                    </Tooltip>
                  </SeparatorList>
                }
                onOpened={() => {
                  goals.expandClassifier()
                }}
                onClosed={() => {
                  goals.foldClassifier()
                }}
                data-test-id='ElementsSection'
                footer={
                  <EditorTreeStatusPanel
                    content={
                      <RunEstimation
                        widthTreeResizableCol={widthTreeResizableCol}
                        disabled={
                          dynamicGroupPending ||
                          estimationStatus === 'Finished' ||
                          groupingProcessStatus === 'InProgress' ||
                          groupingProcessStatus === 'ErrorTimedOut'
                        }
                        withoutDownload
                        delayedRun={conditionChanged}
                        color='primary'
                      />
                    }
                  />
                }
                headerSecondary={
                  <>
                    <Tooltip
                      placement='top'
                      title={
                        //TODO Tooltip
                        dynamicGroupMode ? (
                          <DynamicTooltipWrapper>
                            <DynamicTooltipHeader>
                              Показать исходную иерархию
                            </DynamicTooltipHeader>
                            <DynamicTooltipBody>
                              Отключив режим отображения группировок вы
                              <br />
                              сможете настраивать структуру папок
                            </DynamicTooltipBody>
                          </DynamicTooltipWrapper>
                        ) : (
                          <DynamicTooltipWrapper>
                            <DynamicTooltipHeader>
                              Показать иерархию с группировками.
                            </DynamicTooltipHeader>
                            <DynamicTooltipBody>
                              Данный режим отображает финальную
                              <br /> структуру папок участвующих в расчетах
                            </DynamicTooltipBody>
                          </DynamicTooltipWrapper>
                        )
                      }
                    >
                      <DynamicModeWrapper
                        onClick={onToggleDynamicMode}
                        data-test-ui-type='changeDynamicGroupMode'
                      >
                        {dynamicGroupMode && (
                          <DynamicModeText>
                            Вкл. режим группировок
                          </DynamicModeText>
                        )}
                        <DynamicModeIconButton selected={dynamicGroupMode}>
                          <LayersWithoutSubstrate />
                        </DynamicModeIconButton>
                      </DynamicModeWrapper>
                    </Tooltip>
                  </>
                }
                bodyStyles={{ borderRight: '' }}
                hideCssOnlyDN
              >
                <TreeLoader
                  loading={
                    fetchFlatListItemsPending ||
                    migratePending ||
                    isLock ||
                    copyFolderPending
                  }
                  text='Идет загрузка...'
                />

                <Cover
                  open={
                    currentUserClassifier?.children.length === 0 &&
                    !fetchFlatListItemsPending &&
                    !inCreateNode &&
                    !dynamicGroupPending &&
                    groupingProcessStatus !== 'InProgress'
                  }
                  icon={
                    <Substrate>
                      <WithCursorIcon>
                        <FolderWithPlus
                          color='#DADADA'
                          width={45}
                          height={45}
                        />
                      </WithCursorIcon>
                    </Substrate>
                  }
                  title={
                    <>
                      Создайте желаемую структуру <br /> папок и перенесите в
                      них необходимые <br />
                      элементы из зависимостей
                    </>
                  }
                />

                <EditorTreeWrap
                  openedResultCalculating={openedResultCalculating}
                  searchModel={searchModel}
                  selectViewerRefs={setSelectViewerRefs}
                  currentUserClassifier={currentUserClassifier}
                  dependenciesWithModels$={
                    modelStoreService.dependenciesWithModels$
                  }
                  seoEventHandler={(errorText: string) => {
                    seoEventManager.push({
                      event: 'Gtech_Estimation_Error',
                      payload: {
                        estimationId: currentEstimation.id,
                        errorType: 'red',
                        errorText,
                      },
                    })
                  }}
                  diffService={diffService}
                />

                <ImportEstimation
                  currentUserClassifier$={currentUserClassifier$}
                  estimationId={currentEstimation.id}
                  estimationVersion={currentEstimation.version}
                  seoEventManager={seoEventManager}
                />
              </ResizablePanel>

              <ResizablePanel
                foldedTitle='Редактор формул'
                noHeaderSeparator
                data-test-id='FormulaSection'
                onOpened={() => {
                  goals.expandGroupSettings()
                }}
                onClosed={() => {
                  goals.foldGroupSettings()
                }}
                header={
                  //TODO - Сделать переиспользуемый компонент с кодом ниже
                  <EditorPanelHeader>
                    <EditorPanelHeaderIcon>
                      <CountIconWrap>
                        <CountBig />
                      </CountIconWrap>
                    </EditorPanelHeaderIcon>
                    <EditorPanelHeaderTitle data-test-ui-type='Text'>
                      {(widthColMoreMin || !selectedGroup?.name) &&
                        'Редактор формул'}
                      {widthColMoreMin && selectedGroup?.name && `: `}
                      <Tooltip title={selectedGroup?.name || ''}>
                        <> {truncateText(selectedGroup?.name, 25)}</>
                      </Tooltip>
                    </EditorPanelHeaderTitle>
                  </EditorPanelHeader>
                }
                bodyStyles={{ borderRight: '' }}
              >
                <Cover
                  open={
                    !selectedGroup &&
                    currentUserClassifier?.children.length !== 0
                  }
                  icon={
                    <Substrate p='19px 16px 20px 16px'>
                      <FolderWithoutSubstrate width={40} height={33} />
                    </Substrate>
                  }
                  title={<>Ни одна папка в третьей колонке не выбрана</>}
                  pb='46px'
                />
                <GroupSettings
                  setOpenedResultCalculating={setOpenedResultCalculating}
                  widthFormulaResizableCol={widthFormulaResizableCol}
                  nodes$={classifierService.nodes$}
                  classifierId={currentUserClassifier.id}
                />
              </ResizablePanel>

              <ResizablePanel
                foldedTitle='Результат расчёта'
                noHeaderSeparator
                data-test-id='ResultSection'
                header={
                  <EditorPanelHeader>
                    <EditorPanelHeaderIcon>
                      <CountIconWrap>
                        <CheckListIcon />
                      </CountIconWrap>
                    </EditorPanelHeaderIcon>
                    <EditorPanelHeaderTitle>
                      Результат расчёта
                    </EditorPanelHeaderTitle>
                  </EditorPanelHeader>
                }
                bodyStyles={{ borderRight: '' }}
              >
                {renderEstimationReport ? (
                  renderEstimationReport({
                    setSelectViewerRefs,
                    widthResultsResizableCol,
                    currentEstimation,
                    conditionChanged,
                    dynamicGroupPending,
                    estimationStatus,
                    groupingProcessStatus,
                    nonStartedEstimation,
                    notActualResult,
                    reportPending,
                  })
                ) : (
                  <EstimationReport
                    action={
                      <RunEstimation
                        color='secondary'
                        delayedRun={conditionChanged}
                        size='regular'
                        disabled={
                          dynamicGroupPending ||
                          groupingProcessStatus === 'InProgress' ||
                          groupingProcessStatus === 'ErrorTimedOut'
                        }
                      />
                    }
                    nodes$={classifierService.nodes$}
                    notActualResult={
                      <NotActual
                        actionButton={
                          <RunEstimation
                            delayedRun={conditionChanged}
                            size='regular'
                            disabled={
                              dynamicGroupPending ||
                              groupingProcessStatus === 'InProgress' ||
                              groupingProcessStatus === 'ErrorTimedOut'
                            }
                          />
                        }
                        notActualResult={notActualResult}
                      />
                    }
                    showFooter={!notActualResult}
                    notStarted={nonStartedEstimation}
                    pending={
                      estimationStatus === 'Started' ||
                      estimationStatus === 'InProgress' ||
                      reportPending
                    }
                    previewResult$={previewResult$}
                    isGroupItem={isGroupItem}
                    containerMounted={containerMounted}
                    parentData$={parentData$}
                    searchModel={searchModel}
                    selectViewerRefs={setSelectViewerRefs}
                    widthResultsResizableCol={widthResultsResizableCol}
                  />
                )}
              </ResizablePanel>
            </Resizable>
          </PageContentContainer>
          <VersionHistory
            open={openVersionHistory}
            onClose={onCloseVersionHistory}
            onRedirectToLast={() => {
              history.push(`/estimation/${currentEstimation.id}`)
            }}
            isCurrentVersion={isCurrentVersion}
            beforeList={NamedVersionsSwitch}
            versionList={
              <VersionList
                onMenuClick={setCtxMenu}
                currentVersion={currentEstimation.version}
                dates={versionDates}
                versions={versions}
                fetchVersions={async versionDate => {
                  await api.Estimation.fetchVersionByDate.defaultContext.submit(
                    {
                      estimationId: currentEstimation.id,
                      versionDate,
                    },
                  )
                }}
                anotherListOpened={namedVersions}
                contextMenu={!changeNameDialog && ContextMenu()}
                openVersionHistory={openVersionHistory}
                expandModel={expandVersionModel}
                isPublished={isPublishedVersion}
                ctxMenu={ctxMenu}
                pendingMapVersionByDates={pendingMapVersionByDates}
                createPendingVersionByDatesKey={({ versionDate }) =>
                  createPendingVersionByDatesKey({ estimationId, versionDate })
                }
              />
            }
          />

          <filterPanelService.FilterPanel
            classifierId={currentEstimation.classifierId}
            classifierVersion={currentEstimation.classifierVersion}
            searchModel={searchModel}
            nodes$={classifierService.nodes$}
            selectViewerRefs={setSelectViewerRefs}
            currentEntity={currentUserClassifier}
            dependenciesWithModels$={modelStoreService.dependenciesWithModels$}
            seoEventHandler={() => {
              seoEventManager.push({
                event: 'Gtech_Estimation_EstimationGlobalFilterSet',
                payload: {
                  estimationId: currentEstimation.id,
                },
              })
            }}
          />

          {ChangeVersionNameDialog()}
        </Box>
      </>
    )
  },
)

EstimationEditorPage.displayName = 'EstimationEditorPage'
