import { IconButton, IconButtonProps } from '@gmini/ui-kit'
import styled from 'styled-components'

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const CompactIconButton = styled(IconButton)`
  ${(props: IconButtonProps) =>
    !props.disabled &&
    props.selected &&
    'background: rgba(76, 94, 207, 1); rect { fill: #fff }; '};
`

export const ExpandedIconButton = styled(IconButton)`
  ${(props: IconButtonProps) =>
    !props.disabled &&
    props.selected &&
    'background: rgba(76, 94, 207, 1); rect { fill: #fff }; '};
`
