import React, { SVGAttributes } from 'react'

type SvgComponentType = SVGAttributes<SVGSVGElement> & {
  color?: string
}

export const ExpandedView = ({
  color = '#00003D',
  width = '36',
  height = '36',
  ...other
}: SvgComponentType) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...other}
  >
    <g opacity='0.5'>
      <rect x='7' y='12' width='22' height='5' rx='1' fill={color} />
      <rect x='7' y='7' width='22' height='3' rx='1' fill={color} />
      <rect x='7' y='19' width='22' height='10' rx='1' fill={color} />
    </g>
  </svg>
)
