import { useStore } from 'effector-react'

import { ToggleContentIcon, ToggleContentWrapper } from './ToggleContent.styled'
import { toggleAllVisibility, tablesContentView$ } from './model'

type ToggleContentProps = {
  reportId: number
}

export const ToggleContent = ({ reportId }: ToggleContentProps) => {
  const allTablesVisible = useStore(tablesContentView$)
  if (!allTablesVisible || !allTablesVisible[reportId]) {
    return null
  }
  const isAllVisible = allTablesVisible[reportId].every(value => value.state)

  return (
    <ToggleContentWrapper>
      <ToggleContentIcon
        onClick={() => toggleAllVisibility(reportId)}
        expanded={isAllVisible}
      />
    </ToggleContentWrapper>
  )
}
