import styled, { css } from 'styled-components'

import { ArrowExpandMore } from '../../../icons'

export const ToggleContentWrapper = styled.div`
  display: flex;
  align-items: center;
`

type ToggleContentIconProps = {
  expanded?: boolean
}

export const ToggleContentIcon = styled(ArrowExpandMore)`
  cursor: pointer;
  transition: transform ease-in-out 0.3s;
  ${({ expanded }: ToggleContentIconProps) =>
    expanded
      ? css`
          transform: rotateX(180deg);
        `
      : ''}
  border-radius: 4px;
`
