import { useStore } from 'effector-react'
import { combine, createEffect, createStore, Store } from 'effector'
import { equals } from 'ramda'

import { node } from '@gmini/common/lib/classifier-service'
import * as smApi from '@gmini/sm-api-sdk'
import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import { ref } from '@gmini/common/lib/classifier-service/adapters'

import { classifierService } from '../../services/classifierService'
import { estimationService } from '../../services/estimationService'

export interface UserClassifierProps {
  readonly id: number
  readonly version: number
}

const classifierId$ = estimationService.estimation.currentEstimation$.map(
  ch => ch?.classifierId || null,
)

export const fetchSourceAndTargetUserClassifier = createEffect({
  handler: async ({
    source,
    target,
  }: {
    target: api.Estimation
    source: api.Estimation
  }): Promise<DiffCurrentUserClassifier> => {
    const sourceClassifier = await smApi.UserClassifier.fetch.defaultContext({
      id: source.classifierId,
      version: source.classifierVersion,
    })

    const targetClassifier = await smApi.UserClassifier.fetch.defaultContext({
      id: target.classifierId,
      version: target.classifierVersion,
    })

    return { sourceClassifier, targetClassifier }
  },
})

export type DiffCurrentUserClassifier = {
  sourceClassifier: smApi.UserClassifier.Populated
  targetClassifier: smApi.UserClassifier.Populated
}

const diffCurrentUserClassifier$ =
  createStore<DiffCurrentUserClassifier | null>(null).on(
    fetchSourceAndTargetUserClassifier.doneData,
    (_, result) => result,
  )

export const currentUserClassifier$: Store<null | node.UserClassifierNode> =
  combine(
    classifierService.userClassifier.nodes$,
    diffCurrentUserClassifier$,
    classifierId$,
    (nodes, diffCurrentUserClassifier, id) => {
      if (!id) {
        return null
      }

      const userClassifierNode = nodes[id]
      if (!userClassifierNode) {
        return null
      }

      if (diffCurrentUserClassifier) {
        const sourceClassifiers = [
          ...(diffCurrentUserClassifier?.sourceClassifier.sourceClassifiers ||
            []),
          ...(diffCurrentUserClassifier?.targetClassifier.sourceClassifiers ||
            []),
        ].reduce(
          (acc: smApi.UserClassifier.Data['sourceClassifiers'], item) => {
            const sameItemInAcc = acc.find(accItem => accItem.id === item.id)
            if (sameItemInAcc) {
              if (item.version > sameItemInAcc.version) {
                return [...acc.filter(accItem => accItem.id !== item.id), item]
              }

              return acc
            }

            return [...acc, item]
          },
          [],
        )

        return {
          ...userClassifierNode,
          sourceClassifiers: [...sourceClassifiers].map(ref),
        }
      }

      return userClassifierNode
    },
  )

export const projectUrn$ = currentUserClassifier$.map(classifier =>
  classifier ? classifier.projectUrn : null,
)

export type CurrentUserClassifierProps = {
  id: number
  version: number
}

export const currentUserClassifierProps$: Store<CurrentUserClassifierProps | null> =
  currentUserClassifier$
    .map(classifier =>
      classifier ? { id: classifier.id, version: classifier.version } : null,
    )
    .map((next, prev) =>
      prev === null || !equals(next, prev)
        ? next
        : (prev as CurrentUserClassifierProps),
    )

export function useCurrentUserClassifier(): null | node.UserClassifierNode {
  return useStore(currentUserClassifier$)
}
