import styled from 'styled-components'

export const SegmentPath = styled.div`
  color: #3f51b5;
`

export const LastSegmentPath = styled(SegmentPath)`
  color: #3f51b5;
  background-color: rgba(76, 94, 207, 0.1);
  height: 20px;
  padding: 0 5px;
  border-radius: 4px;
`

export const StyledSlash = styled.div`
  padding: 0 5px;
  color: #3f51b5;
  &:after {
    content: '/';
  }
`

export const PathWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const CirclesWrapper = styled.div`
  margin-left: 8px;
`
