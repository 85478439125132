import styled from 'styled-components'
import { Grid } from 'react-virtualized'

export const StyledGrid = styled(Grid)`
  outline: none;
  scroll-behavior: smooth;
`
export const HeaderGrid = styled(Grid)`
  outline: none;
  overflow: hidden !important;
`
