import { SeparatorList } from '@gmini/ui-kit'

import { ReportGroup, SelectReport } from '../SelectReport/SelectReport'
import { ReportSubtotals } from '../Subtotals/reportSubtotals'
import { ToggleContent } from '../ToggleContent/ToggleContent'

import { SelectView } from '../SelectView/SelectView'

import {
  EstimationReportHeaderIcons,
  EstimationReportHeaderWrap,
  StatusPanelLayout,
} from './EstimationReportHeader.styled'

type EstimationReportHeaderProps<G> = {
  rootGroupList: G[]
  setCurrentReport: (report: G) => void
  showFooter: boolean
  action: React.ReactNode
  currentReport?: G
}

export const EstimationReportHeader = <G extends ReportGroup>({
  rootGroupList,
  currentReport,
  setCurrentReport,
  showFooter,
  action,
}: EstimationReportHeaderProps<G>) => (
  <EstimationReportHeaderWrap>
    <SelectReport
      rootReportGroups={rootGroupList}
      onReportChange={report => {
        setCurrentReport(report as G)
      }}
    />
    <EstimationReportHeaderIcons>
      <SeparatorList>
        <ReportSubtotals />
        {currentReport && <ToggleContent reportId={currentReport.elementId} />}
        <SelectView />
        {showFooter && <StatusPanelLayout>{action}</StatusPanelLayout>}
      </SeparatorList>
    </EstimationReportHeaderIcons>
  </EstimationReportHeaderWrap>
)
