import styled, { css } from 'styled-components'

import { ArrowExpandMore } from '../icons'

export const TableWrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: fit-content;
  margin: 10px 20px 0 20px;
`

type TableHeaderrops = {
  height: number
  width?: number
}

export const TableHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  ${({ width }: TableHeaderrops) => `width: ${width}px;`}
  ${({ height }: TableHeaderrops) => `height: ${height}px;`}
  padding-left: 7px;
  border: 1px solid rgba(229, 231, 241, 1);
  user-select: none;
  margin-right: 20px;
  white-space: nowrap;
`

type TableBodyWrapperProps = {
  height: number
  width: number
}

export const TableBodyWrapper = styled.div`
  position: relative;
  ${({ width }: TableBodyWrapperProps) => `width: ${width}px;`}
  ${({ height }: TableBodyWrapperProps) => `height: ${height}px;`}
`
type TableBodyProps = {
  height: number
  borderHeight: number
}

export const TableBody = styled.div`
  position: relative;
  ${({ height }: TableBodyProps) => `height: ${height}px;`}
  ${({ borderHeight }: TableBodyProps) =>
    borderHeight ? `border: 1px solid rgba(229, 231, 241, 1)` : ''}
`

type ArrowExpandMoreStyledProps = {
  expanded?: boolean
}

export const ArrowExpandMoreStyled = styled(ArrowExpandMore)`
  cursor: pointer;
  transition: transform ease-in-out 0.3s;
  ${({ expanded }: ArrowExpandMoreStyledProps) =>
    expanded
      ? css`
          transform: rotateX(180deg);
        `
      : ''}
  border-radius: 4px;
`
