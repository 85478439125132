/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import {
  AutoSizer,
  Grid,
  GridCellProps,
  ScrollSync,
  OnScrollParams,
} from 'react-virtualized'

import { AbstractColumn, VirtualizedTableProps } from './virtualizedTable.type'
import { HeaderGrid, StyledGrid } from './VirtualizedTable.styled'

// eslint-disable-next-line @typescript-eslint/ban-types
export function VirtualizedTable<C extends AbstractColumn, I extends {}>({
  columns,
  columnRowCount = 1,
  items,
  columnRenderer,
  itemRenderer,
  rowHeight = 30,
  colHeight = 36,
  headerWrapComponent,
  columnWidth,
  columnCellCount = columns.length,
}: VirtualizedTableProps<C, I>) {
  const headerRef = React.useRef<Grid | null>(null)
  const rowRef = React.useRef<Grid | null>(null)
  const [hoveredRowIdx, setHoveredRowIdx] = React.useState<number | null>(null)

  React.useEffect(() => {
    if (columns) {
      headerRef.current?.recomputeGridSize()
      rowRef.current?.recomputeGridSize()
    }
  }, [columns])

  const columnRender = React.useCallback(
    ({ columnIndex, rowIndex, style }: GridCellProps) => {
      const column = columns[columnIndex]

      if (!column) {
        throw new Error(
          `[VirtualizedTable]: Not found column with index: ${columnIndex}`,
        )
      }

      return (
        <div
          style={{
            ...style,
            backgroundColor: 'rgba(244, 244, 248, 0.5)',
            height: colHeight,
          }}
        >
          {columnRenderer(column, columnIndex, rowIndex)}
        </div>
      )
    },
    [colHeight, columnRenderer, columns],
  )
  const itemRender = React.useCallback(
    ({ columnIndex, rowIndex, style }: GridCellProps) => {
      const column = columns[columnIndex]
      const item = items[rowIndex]

      return (
        <div
          onMouseEnter={() => setHoveredRowIdx(rowIndex)}
          onMouseLeave={() => setHoveredRowIdx(null)}
          style={{ ...style }}
        >
          {itemRenderer({
            item,
            column,
            rowIndex,
            hover: rowIndex === hoveredRowIdx,
            columnIndex,
          })}
        </div>
      )
    },
    [columns, itemRenderer, hoveredRowIdx, items],
  )

  const headerHeight = colHeight * columnRowCount

  const renderHeader = React.useCallback(
    ({
      width,
      scrollLeft,
      onScroll,
    }: {
      width: number
      scrollLeft: number
      onScroll: (params: OnScrollParams) => void
    }) => {
      const headerGrid = (
        <HeaderGrid
          ref={headerRef}
          columnWidth={columnWidth}
          columnCount={columns.length}
          height={headerHeight}
          cellRenderer={columnRender}
          rowHeight={colHeight}
          rowCount={columnRowCount}
          width={width}
          scrollLeft={scrollLeft}
          onScroll={onScroll}
        />
      )

      return headerWrapComponent
        ? headerWrapComponent({
            width,
            content: headerGrid,
          })
        : headerGrid
    },
    [
      colHeight,
      columnRender,
      columnRowCount,
      headerHeight,
      columnWidth,
      columns.length,
      headerWrapComponent,
    ],
  )

  return (
    <ScrollSync>
      {({ onScroll, scrollLeft }) => (
        <AutoSizer>
          {({ width, height }) => (
            <div style={{ height: `${height}px` }}>
              <div
                style={{
                  height: headerHeight,
                  width,
                }}
              >
                {renderHeader({
                  width,
                  scrollLeft,
                  onScroll,
                })}
              </div>

              <div
                style={{
                  width,
                }}
              >
                <StyledGrid
                  ref={rowRef}
                  columnWidth={columnWidth}
                  columnCount={columnCellCount}
                  height={height - headerHeight}
                  cellRenderer={itemRender}
                  rowCount={items.length}
                  width={width}
                  rowHeight={rowHeight}
                  onScroll={onScroll}
                  scrollLeft={scrollLeft}
                  autoHeight
                />
              </div>
            </div>
          )}
        </AutoSizer>
      )}
    </ScrollSync>
  )
}
