import styled, { css } from 'styled-components'

import { ArrowExpandMore } from '../icons'

type CellProps = {
  isCompactView?: boolean
  isSubTotalRow?: boolean
  modificationColor?: string
  isDeleted?: boolean
}

export const Cell = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15px;
  user-select: none;
  cursor: pointer;
  ${({ isDeleted }: CellProps) =>
    isDeleted ? 'text-decoration: line-through;' : ''}
  /* прокинуть modificationColor в LongText */
  span:not(:has(div[data-mui-internal-clone-element='true'])),
  del:not(:has(div[data-mui-internal-clone-element='true'])) {
    span,
    del {
      ${({ modificationColor }) =>
        modificationColor ? `background: ${modificationColor};` : ''}
    }
  }
  div[data-mui-internal-clone-element='true'] {
    span,
    del {
      span,
      del {
        ${({ modificationColor }) =>
          modificationColor ? `background: ${modificationColor};` : ''}
      }
    }
  }
  ${({ isCompactView }: CellProps) =>
    isCompactView ? '-webkit-line-clamp: 1' : '-webkit-line-clamp: 4;'}
  ${({ isSubTotalRow }: CellProps) =>
    isSubTotalRow ? 'font-weight: 500;' : ''}
`

type CellWrapperProps = {
  isNameCell?: boolean
  darken?: boolean
  hover?: boolean
  isNumberCell?: boolean
  isCompactView?: boolean
  isSelectedRow?: boolean
}

export const CellWrapper = styled.div`
  display: flex;
  height: 100%;
  border-bottom: 1px solid #e5e7f1;
  background: white;
  padding: 0 12px;
  overflow: hidden;
  ${({ darken }: CellWrapperProps) =>
    darken ? 'background: rgba(0, 5, 100, 0.04);' : ''}
  ${({ hover }: CellWrapperProps) =>
    hover &&
    `
      background-color: rgba(0, 5, 100, 0.04);
      transition: background-color 0.3s ease;
    `}
  ${({ isSelectedRow }: CellWrapperProps) =>
    isSelectedRow &&
    `
      background-color: rgba(125, 177, 255, 0.5);
      transition: background-color 0.3s ease;
    `}
  ${({ isNumberCell }: CellWrapperProps) =>
    isNumberCell ? 'justify-content: flex-end;' : ''}
  ${({ isCompactView }: CellWrapperProps) =>
    isCompactView
      ? 'align-items: center;'
      : css`
          padding-top: 12px;
          padding-bottom: 12px;
        `}
`

type ArrowExpandMoreStyledProps = {
  expanded?: boolean
}

export const ArrowExpandMoreStyled = styled(ArrowExpandMore)`
  cursor: pointer;
  transition: transform ease-in-out 0.3s;
  ${({ expanded }: ArrowExpandMoreStyledProps) =>
    expanded
      ? css`
          transform: rotateX(180deg);
        `
      : ''}
  border-radius: 4px;
  & path {
    opacity: 0.5;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.03);
    & path {
      opacity: 0.8;
    }
  }
`

export const EmptyFolderText = styled.div`
  color: #9a9db0;
`

export const HighlitedText = styled.span`
  padding: 0 3px;
  border-radius: 4px;
  background: rgba(76, 94, 207, 0.15);
`
