import styled, { css } from 'styled-components'

const ColumnCellStyles = css`
  margin: ${({ m }: ColumnCellProps) => (m ? `${m}` : '11px 0')};
  padding: ${({ p }: ColumnCellProps) => (p ? `${p}` : '0 12px')};
  white-space: nowrap;
  border-right: ${({ borderRight }: ColumnCellProps) =>
    borderRight && '1px solid rgba(224, 224, 224, 1)'};
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #a2a3b7;
  position: relative;
  overflow: hidden;
  ${({ width }: ColumnCellProps) => (width ? `width: ${width}px;` : '')}
  ${({ resizing }: ColumnCellProps) =>
    resizing ? `background: rgba(76,94,207,0.15);` : ''}
  ${({ isNumberCell }: ColumnCellProps) =>
    isNumberCell ? 'text-align: right;' : ''}
`

type ColumnCellProps = {
  m?: string
  p?: string
  borderRight?: boolean
  width?: number
  resizing?: boolean
  isNumberCell?: boolean
}

export const ColumnCell = styled.div`
  ${ColumnCellStyles}
`

export const NameColumnCell = styled.div`
  ${ColumnCellStyles}
  border-right: 1px solid rgba(224, 224, 224, 1);
  padding: 0 8px;
  color: #353b60;
`

export const DragHandle = styled.div`
  position: absolute;
  cursor: col-resize;
  width: 11px;
  height: 100%;
  right: -6px;
  z-index: 1;
`
