import React, { memo, useCallback, useEffect } from 'react'
import { useStore } from 'effector-react'

import { ReadonlyModeProvider, useDiffMode } from '@gmini/common'
import * as smApi from '@gmini/sm-api-sdk'

import { RoundedCross } from '@gmini/ui-kit'

import { useHistory } from 'react-router-dom'

import { classifierService } from '../../services/classifierService'

import { estimationService } from '../../services/estimationService'

import { fetchSourceAndTargetUserClassifier } from '../CurrentUserClassifier'

import {
  CloseButton,
  EstimationDiffContent,
  EstimationDiffHeader,
  EstimationDiffHeaderCompare,
  EstimationDiffWrapper,
  GoToNewVersionButton,
} from './EstimationDiffWrap.styled'
import { DiffIcon } from './DiffIcon'

import {
  EstimationEditorPage,
  RenderEstimationReportProps,
} from './EstimationEditorPage'
import { RunEstimation } from './RunEstimation'
import { EstimationReportDiff } from './EstimationReport/EstimationReportDiff'
import { NotActual } from './EstimationReport/Report/NotActual'

import { isGroupItem } from './model/reportDiffService'

import { searchModel } from './model/current/searchModel'
import { diffService } from './model/diffService'

diffService.fetchSourceAndTargetEntities.doneData.watch(
  ({ source, target }) => {
    fetchSourceAndTargetUserClassifier({ source, target })
  },
)

export const EditorDiffWrap = memo(() => {
  const { id, sourceVersion, targetVersion } = useDiffMode()
  const diffEntities = useStore(diffService.diffEntities$)
  const history = useHistory()

  const currentEstimation = useStore(
    estimationService.estimation.currentEstimation$,
  )

  useEffect(() => {
    smApi.Project.fetchList.defaultContext.submit()
  }, [])

  useEffect(() => {
    diffService.fetchSourceAndTargetEntities({
      id,
      sourceVersion,
      targetVersion,
    })
  }, [id, sourceVersion, targetVersion])

  const renderEstimationReport = useCallback(
    ({
      setSelectViewerRefs,
      widthResultsResizableCol,
      currentEstimation,
      conditionChanged,
      dynamicGroupPending,
      estimationStatus,
      groupingProcessStatus,
      nonStartedEstimation,
      notActualResult,
      reportPending,
    }: RenderEstimationReportProps) => (
      <EstimationReportDiff
        action={
          <RunEstimation
            color='secondary'
            delayedRun={conditionChanged}
            size='regular'
            disabled={
              dynamicGroupPending ||
              groupingProcessStatus === 'InProgress' ||
              groupingProcessStatus === 'ErrorTimedOut'
            }
          />
        }
        nodes$={classifierService.nodes$}
        notActualResult={
          <NotActual
            actionButton={
              <RunEstimation
                delayedRun={conditionChanged}
                size='regular'
                disabled={
                  dynamicGroupPending ||
                  groupingProcessStatus === 'InProgress' ||
                  groupingProcessStatus === 'ErrorTimedOut'
                }
              />
            }
            notActualResult={notActualResult}
          />
        }
        showFooter={!notActualResult}
        notStarted={nonStartedEstimation}
        pending={
          estimationStatus === 'Started' ||
          estimationStatus === 'InProgress' ||
          reportPending
        }
        isGroupItem={isGroupItem}
        searchModel={searchModel}
        selectViewerRefs={setSelectViewerRefs}
        widthResultsResizableCol={widthResultsResizableCol}
        currentEstimation={currentEstimation}
      />
    ),
    [],
  )

  const onClose = async () => {
    if (!diffEntities.source || !diffEntities.target) {
      throw new Error('diffEntities.source or diffEntities.target is undefined')
    }
    const res = await smApi.cancelMigration({
      classifierId: diffEntities.target.classifierId,
      classifierVersion: diffEntities.target.classifierVersion,
      oldClassifierVersion: diffEntities.source.classifierVersion,
      commitMigrate: false,
    })

    if (res) {
      history.replace({ pathname: `/estimation/${id}` })
    }
  }

  const onGoToNewVersion = async () => {
    if (!diffEntities.source || !diffEntities.target) {
      throw new Error('diffEntities.source or diffEntities.target is undefined')
    }
    const res = await smApi.cancelMigration({
      classifierId: diffEntities.target.classifierId,
      classifierVersion: diffEntities.target.classifierVersion,
      oldClassifierVersion: diffEntities.source.classifierVersion,
      commitMigrate: true,
    })

    if (res) {
      history.replace({ pathname: `/estimation/${id}` })
    }
  }

  if (!currentEstimation) {
    return null
  }

  return (
    <ReadonlyModeProvider
      value={{
        enabled: true,
        description: 'Расчёт находится в режиме просмотра',
      }}
    >
      <EstimationDiffWrapper>
        <EstimationDiffHeader>
          <DiffIcon />
          <EstimationDiffHeaderCompare>
            Сравнение версий моделей
            <GoToNewVersionButton
              onClick={onGoToNewVersion}
              color='info'
              size='regular'
            >
              Перейти на новые версии моделей
            </GoToNewVersionButton>
          </EstimationDiffHeaderCompare>
          <CloseButton size='regular' onClick={onClose}>
            <RoundedCross />
          </CloseButton>
        </EstimationDiffHeader>
        <EstimationDiffContent>
          <EstimationEditorPage
            isCurrentVersion={false}
            currentEstimation={currentEstimation}
            renderEstimationReport={renderEstimationReport}
          />
        </EstimationDiffContent>
      </EstimationDiffWrapper>
    </ReadonlyModeProvider>
  )
})
EditorDiffWrap.displayName = 'EditorDiffWrap'
