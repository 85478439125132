import { EstimationReport } from '@gmini/sm-api-sdk/lib/EstimationApi'

export type ResultColumn = {
  columnId: number
  name: string
  type: 'SimpleField' | 'ComplexField'
  isLastColumn?: boolean
}

type BaseItem = {
  elementId: number
  externalId: string
  name: string
  parentExternalId: string | null
  values: { columnId: number }[]
}

type BimItem = BaseItem & {
  elementType: EstimationReport.TreeBimItem['elementType']
}

type BimItemDiff = BaseItem &
  BimItem & {
    modifications: EstimationReport.Modification[]
  }

type GroupItem = BaseItem & {
  elementType: EstimationReport.TreeGroupItem['elementType']
}

type GroupItemDiff = GroupItem & {
  modifications: EstimationReport.Modification[]
}

type ReportItem<I, G> = {
  path: string[]
  rows: I[]
  subTotals: G
  columns: ResultColumn[]
}

type ReportResult<I, G> = {
  reportByRootGroupId: Record<number, ReportItem<I, G>[]>
  rootGroupList: GroupItem[]
}

type DataColumn = {
  id: number
  name: string
  type: 'SimpleField' | 'ComplexField'
}

export type Data<I, G> = {
  tree: (I | G)[]
  columns: DataColumn[]
}

export function calculateReportResult<
  I extends BimItem | BimItemDiff,
  G extends GroupItem | GroupItemDiff,
  R extends Data<I, G>,
>(data: R, isGroupItem: (item: I | G) => boolean) {
  const structureElementsByParentExternalId = data.tree.reduce(
    (acc: Record<string, I[]>, item) => {
      if (item.elementType === 'BimStandardSize' && item.parentExternalId) {
        acc[item.parentExternalId] = [
          ...(acc[item.parentExternalId] || []),
          item as I,
        ]
      }
      return acc
    },
    {},
  )

  const groupsWithStructureElements = data.tree.filter(
    el =>
      structureElementsByParentExternalId[el.externalId]?.length &&
      el.elementType !== 'BimFamily' &&
      el.elementType !== 'BimCategory' &&
      el.elementType !== 'BimModel',
  ) as G[]

  const treeGroupItemsByExternalId = data.tree.reduce(
    (acc: Record<string, G>, item) => {
      if (isGroupItem(item)) {
        acc[item.externalId] = item as G
      }
      return acc
    },
    {},
  )

  const getPathByItem = (item: G) => {
    const path: string[] = []
    const addPath = (item: G) => {
      path.unshift(item.name)
      if (item.parentExternalId) {
        const parentItem = treeGroupItemsByExternalId[item.parentExternalId]
        if (parentItem) {
          addPath(parentItem)
        }
      }
    }

    addPath(item)

    return path
  }

  const getRootByItem = (item: G): G | null => {
    let root: G | null = null

    const getNext = (item: G) => {
      if (item.parentExternalId !== null) {
        const parentItem = treeGroupItemsByExternalId[item.parentExternalId]
        if (parentItem) {
          getNext(parentItem)
        }
      } else {
        root = item
      }
    }

    getNext(item)

    return root
  }

  const pathByTreeGroupExternalId = groupsWithStructureElements.reduce(
    (acc: Record<string, string[]>, item) => {
      acc[item.externalId] = getPathByItem(item)
      return acc
    },
    {},
  )

  const reportByRootGroupId = data.tree.reduce(
    (acc: ReportResult<I, G>['reportByRootGroupId'], item) => {
      const isRootGroup = item.parentExternalId === null

      if (isRootGroup) {
        acc[item.elementId] = groupsWithStructureElements
          .filter(group => {
            const root = getRootByItem(group)
            if (!root) {
              return false
            }

            return root.externalId === item.externalId
          })
          .map(group => {
            const structureElements =
              structureElementsByParentExternalId[group.externalId]

            const columns = data.columns.filter(column =>
              structureElements[0].values.some(
                value => value.columnId === column.id,
              ),
            )

            const subTotals = {
              ...group,
              name: 'Подытоги',
            }

            return {
              columns: columns.map(column => ({
                columnId: column.id,
                name: column.name,
                type: column.type,
              })),
              rows: structureElements,
              subTotals,
              path: pathByTreeGroupExternalId[group.externalId],
              modifications: (group as GroupItemDiff)?.modifications || [],
            }
          })
      }

      return acc
    },
    {},
  )

  return {
    rootGroupList: data.tree.filter(
      node => Number(node.parentExternalId) === 0 && isGroupItem(node),
    ) as G[],
    reportByRootGroupId,
  }
}
