import { LongText } from '@gmini/ui-kit'

import React from 'react'

import { CtxMenuBaseProps } from '@gmini/common'

import { AbstractColumn } from '../VirtualizedTable'

import { Cell, CellWrapper } from './DefaultRowCellRenderer.styled'

export type DefaultRowCellRendererProps<C, I, V> = {
  row: I
  column: C
  columnIndex: number
  hover: boolean
  getRowName: (item: I) => string
  getRowModification: (item: I) => string | null
  getCellData: (row: I, column: C) => V | undefined
  getCellValue: (cell: V) => string | null
  getCellType: (cell: V) => string
  setCtxMenu: React.Dispatch<React.SetStateAction<CtxMenuBaseProps<I>>>
  isCompactView?: boolean
  isSubTotalRow?: (item: I) => boolean
  isSelectedRow?: (item: I) => boolean
  getCellModification?: (cell: V) => string | null
}

export const DefaultRowCellRenderer = <
  C extends AbstractColumn,
  // eslint-disable-next-line @typescript-eslint/ban-types
  I extends {},
  // eslint-disable-next-line @typescript-eslint/ban-types
  V extends {},
>({
  row,
  column,
  columnIndex,
  hover,
  getRowName,
  getRowModification,
  getCellData,
  getCellValue,
  getCellType,
  isCompactView,
  isSubTotalRow,
  setCtxMenu,
  isSelectedRow,
  getCellModification,
}: DefaultRowCellRendererProps<C, I, V>) => {
  const onContextMenu = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault()
      setCtxMenu({
        coords: { x: e.clientX, y: e.clientY },
        item: row,
      })
    },
    [row, setCtxMenu],
  )

  const modificationColors: Record<string, string> = {
    ADDED: 'rgba(13, 153, 102, 0.2)',
    UPDATED: 'rgba(245, 166, 35, 0.2)',
    DELETED: 'rgba(217, 87, 94, 0.2)',
    NONE: 'rgba(255, 255, 255, 0)',
  }

  const renderCellValue = (value: string, isDeleted?: boolean) => (
    <LongText
      partSize={15}
      withoutRightText
      tooltipPlacement='bottom'
      tooltipEnterDelay={500}
      Component={isDeleted ? 'del' : 'span'}
    >
      {value}
    </LongText>
  )

  if (column.type === 'System') {
    const rowName = getRowName(row)
    const rowModification = getRowModification(row)
    let modificationColor = rowModification
      ? modificationColors[rowModification]
      : modificationColors.NONE
    if (rowName === 'Подытоги') {
      modificationColor = modificationColors.NONE
    }
    return (
      <CellWrapper
        onContextMenu={onContextMenu}
        hover={hover}
        isCompactView={isCompactView}
        isSelectedRow={isSelectedRow?.(row)}
      >
        <Cell
          modificationColor={modificationColor}
          isSubTotalRow={isSubTotalRow?.(row)}
        >
          {renderCellValue(rowName)}
        </Cell>
      </CellWrapper>
    )
  }

  const cellData = getCellData(row, column)
  if (!cellData || !getCellValue(cellData)) {
    return (
      <CellWrapper
        hover={hover}
        darken={!(columnIndex % 2 === 0)}
        isSelectedRow={isSelectedRow?.(row)}
      />
    )
  }

  const modification = getCellModification?.(cellData)
  const modificationColor = modification
    ? modificationColors[modification]
    : modificationColors.NONE
  let cellValue = getCellValue(cellData)
  if (!isNaN(Number(cellValue))) {
    cellValue = Number(cellValue).toLocaleString('ru-RU', {
      minimumFractionDigits: 2,
    })
  }

  const isDeleted = modification === 'DELETED'
  return (
    <CellWrapper
      hover={hover}
      darken={!(columnIndex % 2 === 0)}
      isNumberCell={getCellType(cellData) === 'ComplexField'}
      isCompactView={isCompactView}
      onContextMenu={onContextMenu}
      isSelectedRow={isSelectedRow?.(row)}
    >
      <Cell
        isCompactView={isCompactView}
        modificationColor={modificationColor}
        isDeleted={isDeleted}
      >
        {renderCellValue(cellValue ?? '', isDeleted)}
      </Cell>
    </CellWrapper>
  )
}
