import styled from 'styled-components'

import { CircularProgress } from '@gmini/ui-kit/lib/CircularProgress'

export const XlsxButtonText = styled.span`
  padding-left: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
`

export const IconWrap = styled.div`
  display: inline-flex;
  min-width: 24px;
  align-items: center;
`

export const IconSpinner = styled(CircularProgress)`
  color: inherit;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: 6px;

  & svg {
    width: 24px !important;
    height: 24px !important;
  }
`

export const IconBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`
