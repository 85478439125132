const modificationColors: Record<string, string> = {
  ADDED: 'rgba(13, 153, 102, 1)',
  UPDATED: 'rgba(255, 223, 172, 1)',
  DELETED: 'rgba(244, 205, 207, 1)',
}

const PADDING = 4

function ModificationCircles({ modifications }: { modifications: string[] }) {
  return (
    <svg width='24' height='8' viewBox='0 0 24 8'>
      {modifications
        .map((modification, index) => (
          <circle
            key={modification}
            cx={PADDING + index * PADDING}
            cy='4'
            r='4'
            fill={modificationColors[modification]}
            opacity='1'
          />
        ))
        .reverse()}
    </svg>
  )
}

export default ModificationCircles
