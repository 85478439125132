import { LongText } from '@gmini/ui-kit'

import { AbstractColumn } from '../VirtualizedTable'

import { ColumnCell } from './DefaultColumnCellRenderer.styled'

export type DefaultColumnCellRendererProps<C> = {
  column: C
  isLastColumn: (column: C) => boolean
}

export const DefaultColumnCellRenderer = <C extends AbstractColumn>({
  column,
  isLastColumn,
}: DefaultColumnCellRendererProps<C>) => (
  <ColumnCell
    isNumberCell={column.type === 'ComplexField'}
    borderRight={!isLastColumn(column)}
  >
    <LongText
      partSize={15}
      withoutRightText
      tooltipPlacement='bottom'
      tooltipEnterDelay={500}
    >
      {column.name}
    </LongText>
  </ColumnCell>
)
