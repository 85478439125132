import React from 'react'

import { Index } from 'react-virtualized'

import { AbstractColumn, VirtualizedTable } from '../VirtualizedTable'

import { ReportVisibilityState } from '../EstimationEditor/EstimationReport/ToggleContent/model'

import {
  TableHeader,
  TableWrapper,
  TableBody,
  ArrowExpandMoreStyled,
  TableBodyWrapper,
} from './ReportTable.styled'

import {
  headerColumnHeight,
  tableColumnMinWidth,
  tableElementColumnMinWidth,
  tableHorizontalPadding,
  tableMaxHeight,
  tableScrollbarHeight,
} from './constants'
import ReportTableHeader from './ReportTableHeader'

type ReportTableProps<D, C, I, M> = {
  tables: D[]
  headerHeight: number
  rowHeight: number
  showSubtotals: boolean
  allTablesVisible: ReportVisibilityState[number]
  toggleTableVisibility: (index: number) => void
  itemRenderer: (item: {
    item: I
    column: C
    columnIndex: number
    hover: boolean
  }) => JSX.Element
  columnRenderer: (column: C) => JSX.Element
  getDataColumns: (table: D) => C[]
  getDataRows: (table: D) => I[]
  getSubtotals: (table: D) => I
  getTablePath: (table: D) => string[]
  widthResultsResizableCol: number
  getModifications?: (table: D) => M[] | undefined
}

// Rename to Table after delete old realization
export function ReportTable<
  // eslint-disable-next-line @typescript-eslint/ban-types
  D extends {},
  C extends AbstractColumn,
  // eslint-disable-next-line @typescript-eslint/ban-types
  I extends {},
  M extends 'ADDED' | 'DELETED' | 'UPDATED' | 'NONE',
>({
  tables,
  rowHeight,
  headerHeight,
  showSubtotals,
  allTablesVisible,
  toggleTableVisibility,
  itemRenderer,
  columnRenderer,
  getDataColumns,
  getDataRows,
  getSubtotals,
  getTablePath,
  widthResultsResizableCol,
  getModifications,
}: ReportTableProps<D, C, I, M>) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const resizebleWidth =
    Math.max(Math.floor(widthResultsResizableCol), tableMaxHeight) -
    tableHorizontalPadding

  const getColumnWidth = (index: Index, length: number) => {
    const elementWidth = Math.min(
      Math.max(
        Math.floor(resizebleWidth / length) * 2,
        tableElementColumnMinWidth,
      ),
      tableElementColumnMinWidth,
    )

    if (index.index === 0) {
      return elementWidth
    }

    return Math.max(
      Math.floor((resizebleWidth - elementWidth) / (length - 1)),
      tableColumnMinWidth,
    )
  }

  if (!tables || !tables.length) {
    return null
  }

  return (
    <>
      {tables.map((data, index) => {
        const columns = getDataColumns(data)
        const dataRows = getDataRows(data)
        const dataSubtotals = getSubtotals(data)
        const rows = showSubtotals ? [...dataRows, dataSubtotals] : dataRows

        const tableBodyHeight =
          rows.length * rowHeight + headerColumnHeight + tableScrollbarHeight
        const tableBodyBorderHeight =
          rows.length * rowHeight + headerColumnHeight

        const isVisible =
          allTablesVisible && allTablesVisible?.[index]
            ? allTablesVisible[index].state
            : true
        const path = getTablePath(data)

        const modifications = getModifications?.(data)

        return (
          <TableWrapper ref={containerRef} key={`table-${path}}`}>
            <TableHeader width={resizebleWidth} height={headerHeight}>
              <ArrowExpandMoreStyled
                width={24}
                height={24}
                onClick={() => toggleTableVisibility(index)}
                expanded={isVisible}
              />
              <ReportTableHeader path={path} modifications={modifications} />
            </TableHeader>
            {isVisible ? (
              <TableBodyWrapper width={resizebleWidth} height={tableBodyHeight}>
                <TableBody
                  height={tableBodyBorderHeight}
                  borderHeight={tableBodyBorderHeight}
                >
                  <VirtualizedTable
                    columns={columns}
                    items={rows}
                    columnRenderer={columnRenderer}
                    itemRenderer={itemRenderer}
                    rowHeight={rowHeight}
                    columnWidth={index => getColumnWidth(index, columns.length)}
                  />
                </TableBody>
              </TableBodyWrapper>
            ) : null}
          </TableWrapper>
        )
      })}
    </>
  )
}
