import { Tooltip } from '@gmini/ui-kit'

import {
  LastSegmentPath,
  SegmentPath,
  StyledSlash,
  PathWrapper,
  CirclesWrapper,
} from './ReportTableHeader.styled'
import ModificationCircles from './ModificationCircles'

type ReportTableHeaderProps<M> = {
  path: string[]
  modifications?: M[]
}

function ReportTableHeader<M extends string>({
  path,
  modifications,
}: ReportTableHeaderProps<M>) {
  const pathElements = path
    .map((segment, index, array) =>
      index === array.length - 1 ? (
        <LastSegmentPath key={segment}>{segment}</LastSegmentPath>
      ) : (
        <SegmentPath key={segment}>{segment}</SegmentPath>
      ),
    )
    .reduce((prev, curr) => (
      <>
        {prev} <StyledSlash /> {curr}
      </>
    ))

  return (
    <Tooltip title={path.join(' / ')} fillAvailable>
      <PathWrapper>
        {pathElements}
        {modifications && modifications.length > 0 && (
          <CirclesWrapper>
            <ModificationCircles modifications={modifications} />
          </CirclesWrapper>
        )}
      </PathWrapper>
    </Tooltip>
  )
}

export default ReportTableHeader
