import styled from 'styled-components'

export const EstimationReportWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0;
`

export const EstimationReportBottomDivider = styled.div`
  height: 56px;
`
