import styled from 'styled-components'

export const EstimationReportHeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20px;
  align-items: center;
`

export const EstimationReportHeaderIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const StatusPanelLayout = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 36px;
  border-top: 1px solid #e5e7f1;
`
