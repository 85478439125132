import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import { createEvent, sample } from 'effector'

import { buildEstimationStatusKey } from '@gmini/common/lib/Services/EstimationService'

import { estimationService } from '../../../services/estimationService'
import { createReportService } from '../EstimationReport/model/createReportService'
import { initializeContentView } from '../EstimationReport/ToggleContent/model'

export const resetEstimationStatus = createEvent<void>()

export const currentEstimationStatus$ = sample({
  source: estimationService.estimation.currentEstimation$,
  clock: estimationService.estimation.estimationStatus$,
  fn: (estimation, statusMap) =>
    estimation && statusMap
      ? statusMap[
          buildEstimationStatusKey({
            estimationId: estimation.id,
            estimationVersion: estimation.version,
          })
        ] || null
      : null,
}).reset(resetEstimationStatus)

const estimationFinished = currentEstimationStatus$.updates.filter({
  fn: value => value === 'Finished',
})

export const {
  previewResult$,
  containerMounted,
  reportPending$,
  resetPreviewResult,
  isGroupItem,
} = createReportService({
  entity$: estimationService.estimation.currentEstimation$,
  fetchTrigger: estimationFinished,
  status$: currentEstimationStatus$,
  reset: api.Estimation.start.trigger,
})

previewResult$.updates.watch(state => {
  if (!state || !state.reportByRootGroupId) {
    return
  }

  Object.keys(state.reportByRootGroupId).forEach((key: string) => {
    const numericKey = Number(key)
    const data = state.reportByRootGroupId[numericKey]
    if (data) {
      initializeContentView({
        [key]: data.map((_item: unknown, index: number) => ({
          index,
          state: true,
        })),
      })
    }
  })
})
