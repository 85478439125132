import { SubtotalIcon, Tooltip } from '@gmini/ui-kit'
import { useStore } from 'effector-react'
import React from 'react'

import { withSubtotals$, switchSubtotals, resetSubtotals } from './model'
import { SubtotalsIconButton } from './reportSubtotals.styled'
import { ReportSubtotalsProps } from './resportSubtotals.type'

export const ReportSubtotals = ({ pending }: ReportSubtotalsProps) => {
  const withSubtotals = useStore(withSubtotals$)

  React.useEffect(() => () => resetSubtotals(), [])

  return (
    <Tooltip
      placement='top'
      title={withSubtotals ? 'Скрыть подытоги' : 'Показать подытоги'}
    >
      <SubtotalsIconButton
        disabled={pending}
        onClick={switchSubtotals}
        selected={withSubtotals}
        size='regular'
      >
        <SubtotalIcon />
      </SubtotalsIconButton>
    </Tooltip>
  )
}
