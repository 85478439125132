import { createStore, createEvent } from 'effector'

export type ReportVisibilityState = Record<
  number,
  Array<{
    index: number
    state: boolean
  }>
>

type ToggleTableVisibilityPayload = {
  reportId: number
  tableIndex: number
}

export const toggleTableVisibility = createEvent<ToggleTableVisibilityPayload>()

export const toggleAllVisibility = createEvent<number>()
export const resetContentView = createEvent()

export const initializeContentView = createEvent<ReportVisibilityState>()

export const tablesContentView$ = createStore<ReportVisibilityState>({})
  .on(initializeContentView, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .on(toggleAllVisibility, (state, key) => {
    const currentReport = state[key]

    if (!currentReport) {
      return state
    }

    const toggledReport = currentReport.map(element => ({
      ...element,
      state: !element.state,
    }))

    return {
      ...state,
      [key]: toggledReport,
    }
  })
  .on(toggleTableVisibility, (state, { reportId, tableIndex }) => {
    const currentReport = state[reportId]

    if (!currentReport || currentReport.length <= tableIndex) {
      return state
    }

    const updatedReport = [...currentReport]
    updatedReport[tableIndex] = {
      ...updatedReport[tableIndex],
      state: !updatedReport[tableIndex].state,
    }

    return {
      ...state,
      [reportId]: updatedReport,
    }
  })
  .reset(resetContentView)
