import React from 'react'
import { EstimationReport as EstimationReportApi } from '@gmini/sm-api-sdk/lib/EstimationApi/EstimationRepo/EstimationReport'

import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'

import { getNode, Nodes } from '@gmini/common/lib/classifier-service'

import { getBimNode, SearchModel, useContextMenu } from '@gmini/common'

import { getViewerRefs } from '@gmini/common/lib/classifier-editor/Common'

import { useStore } from 'effector-react'

import { Store } from 'effector'

import { apiToNodeTypeMap } from '@gmini/common/lib/classifier-service/adapters'

import { ResultColumn } from '../EstimationEditor/EstimationReport/model/calculateReportResult'

import { ReportFlatNode } from '../EstimationEditor/EstimationReport/model/treeModel'

import { SystemColumn } from './systemColumn'
import { DefaultRowCellRenderer } from './DefaultRowCellRenderer'
import { DefaultColumnCellRenderer } from './DefaultColumnCellRenderer'

export function useReportTableProps({
  isCompactTableView,
  isGroupItem,
  nodes$,
  searchModel,
  selectViewerRefs,
}: {
  isCompactTableView: boolean
  isGroupItem: (item: EstimationReportApi.Tree[number]) => boolean
  nodes$: Store<Nodes>
  searchModel: SearchModel
  selectViewerRefs: (value: Record<string, string[]>) => void
}) {
  const nodes = useStore(nodes$)
  const searchNodes = useStore(searchModel.searchNodes$)

  const disabledTooltipTitle = React.useCallback(
    (
      item: EstimationReportApi.TreeBimItem | EstimationReportApi.TreeGroupItem,
    ) => {
      const node = getNode(nodes, {
        type: apiToNodeTypeMap[item.elementType],
        id: item.elementId,
      })

      const notSupportedNode =
        node?.type === 'DynamicBaseGroupNode' ||
        node?.type === 'DynamicGeneratedGroupNode'

      return notSupportedNode ? 'Поиск папок не поддерживается' : ''
    },
    [nodes],
  )

  const { ContextMenu, setCtxMenu } = useContextMenu<ReportFlatNode['node']>([
    {
      title: 'Найти везде',
      icon: Icon.SEARCH,
      onClick: async item => {
        if (
          item.elementType === 'UserClassifierGroup' ||
          item.elementType === 'DynamicBaseGroup'
        ) {
          return
        }

        // GT-490 Найти везде для элемента в динамических подгруппах
        // Костыль для поиска ноды в результатах расчета с группирововками
        // На бэке в результатах расчета BimStandardSize.id === DynamicGeneratedGroup.id
        if (item.elementType === 'BimStandardSize') {
          const dynamicGroupNode = getNode(nodes, {
            id: item.elementId,
            type: 'DynamicGeneratedGroupNode',
          })

          if (!dynamicGroupNode) {
            return
          }

          searchModel.setSearchNodes({
            type: 'search',
            nodes: [
              {
                node: dynamicGroupNode,
                path: [],
              },
            ],
          })
          const bimNode = getBimNode(dynamicGroupNode, nodes)
          if (bimNode && bimNode.viewerRefs) {
            const viewerRefs = await getViewerRefs(bimNode, nodes)
            if (viewerRefs) {
              selectViewerRefs(viewerRefs)
            }
          }
        }
      },
      tooltipTitle: disabledTooltipTitle,
      disabled: item => !!disabledTooltipTitle(item),
    },
  ])

  const isLastColumn = React.useCallback(column => {
    if ('isLastColumn' in column) {
      return column.isLastColumn ?? false
    }
    return false
  }, [])

  const columnRenderer = React.useCallback(
    (column: ResultColumn | SystemColumn) => (
      <DefaultColumnCellRenderer<ResultColumn | SystemColumn>
        column={column}
        isLastColumn={isLastColumn}
      />
    ),
    [isLastColumn],
  )

  const itemRenderer = React.useCallback(
    (item: {
      item:
        | EstimationReportApi.Tree[number]
        | EstimationReportApi.TreeDiff[number]
      column: ResultColumn | SystemColumn
      columnIndex: number
      hover: boolean
    }) => {
      const props = {
        column: item.column,
        row: item.item,
        columnIndex: item.columnIndex,
        hover: item.hover,
        isCompactView: isCompactTableView,
        isSubTotalRow: isGroupItem,
        getRowName: (
          row:
            | EstimationReportApi.Tree[number]
            | EstimationReportApi.TreeDiff[number],
        ) => row.name,
        getRowModification: (
          row:
            | EstimationReportApi.Tree[number]
            | EstimationReportApi.TreeDiff[number],
        ) => {
          if ('modifications' in row) {
            return row.modifications[0]
          }

          return null
        },
        getCellData: (
          row: EstimationReportApi.Tree[number],
          column: ResultColumn | SystemColumn,
        ) =>
          row.values.find(
            value => value.columnId === (column as ResultColumn).columnId,
          ),
        getCellValue: (
          cell: EstimationReportApi.Cell | EstimationReportApi.DiffCell,
        ) => cell.value,
        getCellType: (
          cell: EstimationReportApi.Cell | EstimationReportApi.DiffCell,
        ) => cell.type,
        setCtxMenu,
        isSelectedRow: (row: EstimationReportApi.Tree[number]) => {
          if (!searchNodes || searchNodes?.nodes.length === 0) {
            return false
          }

          const dynamicGroupNode = getNode(nodes, {
            id: row.elementId,
            type: 'DynamicGeneratedGroupNode',
          })

          if (!dynamicGroupNode) {
            return false
          }

          return searchNodes?.nodes.some(node => {
            if (node.node.type === 'BimStandardSizeNode') {
              return node.node.id === dynamicGroupNode.source?.id
            }

            if (node.node.type === 'DynamicGeneratedGroupNode') {
              return node.node.id === dynamicGroupNode.id
            }
          })
        },
        getCellModification: (
          cell: EstimationReportApi.Cell | EstimationReportApi.DiffCell,
        ) => {
          if ('modification' in cell && cell.modification) {
            return cell.modification
          }

          return null
        },
      }

      return <DefaultRowCellRenderer {...props} />
    },
    [isCompactTableView, isGroupItem, nodes, searchNodes, setCtxMenu],
  )

  return {
    columnRenderer,
    itemRenderer,
    ContextMenu,
  }
}
