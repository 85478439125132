import * as React from 'react'
import { useSnackbar } from 'notistack'

import {
  Tooltip,
  Button,
  PlayCircleFilled,
  PlusCircle,
  ColorButton,
  ColorSize,
} from '@gmini/ui-kit'

import { useDownload } from '@gmini/utils'

import { goals } from '@gmini/common/lib/metrika'

import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import { useStore } from 'effector-react'

import {
  estimationReportsFetch,
  estimationService,
} from '../../../services/estimationService'

import { useEstimation } from '../../CurrentEstimation'

import { checkStatusGrouping } from '../../GroupSettings/GroupingsContainer/GroupingContainer.model'

import { useCurrentUserClassifier } from '../../CurrentUserClassifier'

import { withSubtotals$ } from '../EstimationReport/Subtotals/model'

import { seoEventManager } from '../../../config'

import { IconSpinner } from './RunEstimation.styled'

type RunEstimationProps = {
  delayedRun: boolean
  widthTreeResizableCol?: number
  disabled?: boolean
  color?: ColorButton
  withoutDownload?: boolean
  size?: ColorSize
}

export const RunEstimation = React.memo(
  ({
    delayedRun,
    withoutDownload,
    widthTreeResizableCol,
    disabled: _disabled,
    color,
    size = 'small',
  }: RunEstimationProps) => {
    const currCls = useCurrentUserClassifier()!
    const [running, setRunning] = React.useState(false)
    const [groupingProcess, setGroupingProcess] = React.useState(false)
    const withSubtotals = useStore(withSubtotals$)

    const { pending, xlsxReportUrl, estimation, error, emptyCondition } =
      useEstimation({
        estimationService,
        withSubtotals,
      })

    const { enqueueSnackbar } = useSnackbar()

    const startEstimation = React.useCallback(
      (estimation: { estimationId: number; estimationVersion: number }) => {
        goals.runEstimation()
        api.Estimation.start.defaultContext.submit(estimation)

        seoEventManager.push({
          event: 'Gtech_Estimation_EstimationRun',
          payload: {
            estimationId: estimation?.estimationId,
          },
        })
      },
      [],
    )

    React.useEffect(() => {
      const runEstimationSubscription =
        api.Estimation.fetchStatus.defaultContext.doneData.watch(estimation => {
          // Был запущен расчет, но данные формул не были сохранены
          if (estimation.status === 'NotStarted' && running) {
            startEstimation({
              estimationId: estimation!.id,
              estimationVersion: estimation!.version,
            })
            setRunning(false)
          }
        })

      return () => {
        runEstimationSubscription.unsubscribe()
      }
    }, [startEstimation, running])

    const prepareEstimationRun = React.useCallback(async () => {
      const statusGrouping = await checkStatusGrouping({
        classifierId: currCls.id,
        version: currCls.version,
      })

      if (
        statusGrouping.status === 'NotStarted' ||
        statusGrouping.status === 'Error' ||
        statusGrouping.status === 'ErrorTimedOut'
      ) {
        setGroupingProcess(true)
        return
      }

      // Если в редакторе формул есть несохраненные данные
      if (delayedRun) {
        setRunning(true)
        return
      }

      if (!estimation) {
        throw new Error('[RunEstimation]: Estimation is null')
      }

      startEstimation(estimation)
    }, [currCls?.id, currCls?.version, delayedRun, estimation, startEstimation])

    React.useEffect(() => {
      const checkStatusGroupingSubscription =
        checkStatusGrouping.doneData.watch(statusGrouping => {
          if (statusGrouping.status === 'Finished' && groupingProcess) {
            prepareEstimationRun()
            setGroupingProcess(false)
          }

          if (statusGrouping.status === 'Error') {
            setGroupingProcess(false)
            enqueueSnackbar('Произошла ошибка при применении группировок.', {
              variant: 'error',
            })
          }

          if (statusGrouping.status === 'ErrorTimedOut') {
            setGroupingProcess(false)
            enqueueSnackbar(
              'Было добавлено слишком много группировок и/или простых полей',
              {
                variant: 'error',
              },
            )
          }
        })

      return () => {
        checkStatusGroupingSubscription.unsubscribe()
      }
    }, [
      startEstimation,
      running,
      groupingProcess,
      estimation,
      prepareEstimationRun,
      enqueueSnackbar,
    ])

    const onError = React.useCallback(() => {
      enqueueSnackbar(errorText, {
        variant: 'error',
      })
      console.error(xlsxReportUrl)
    }, [enqueueSnackbar, xlsxReportUrl])

    React.useEffect(() => {
      const errStatusSubscription = statusErrorEvent.watch(msg => {
        enqueueSnackbar(statusErrorText, {
          variant: 'error',
        })

        seoEventManager.push({
          event: 'Gtech_Estimation_Error',
          payload: {
            estimationId: estimation?.estimationId,
            errorType: 'red',
            errorText: statusErrorText,
          },
        })
      })

      return () => errStatusSubscription.unsubscribe()
    }, [enqueueSnackbar, error, estimation?.estimationId])

    const { execute, pending: downloadPending } = useDownload({
      onError,
      url: xlsxReportUrl,
      fetchService: estimationReportsFetch,
    })

    const onDownloadClick = React.useCallback(() => {
      execute()
      goals.downloadReportEstimation()

      seoEventManager.push({
        event: 'Gtech_Estimation_EstimationExport',
        payload: {
          estimationId: estimation?.estimationId,
        },
      })
    }, [estimation?.estimationId, execute])

    const runDisabled = React.useMemo((): string => {
      if (pending) {
        return 'Загрузка'
      } else if (emptyCondition) {
        return 'Не выбрано ни одно условие проверки'
      }

      return ''
    }, [emptyCondition, pending])

    const adaptiveMode = Number(widthTreeResizableCol) < 180
    let tooltipRunCheckup = runDisabled

    if (!tooltipRunCheckup && adaptiveMode) {
      tooltipRunCheckup = 'Запустить проверку'
    }

    if (xlsxReportUrl && !withoutDownload && !delayedRun) {
      return (
        <Tooltip title={adaptiveMode ? 'Экспорт в XLS' : ''}>
          <Button
            color={color}
            data-test-id='exportToXLS'
            onClick={onDownloadClick}
            disabled={downloadPending || delayedRun}
            leftIcon={
              <>
                <PlusCircle />
                {downloadPending && <IconSpinner size={24} />}
              </>
            }
            size={size}
          >
            {!adaptiveMode && 'Экспорт в XLS'}
          </Button>
        </Tooltip>
      )
    }

    const disabled = (!delayedRun && !!_disabled) || groupingProcess

    return (
      <Tooltip title={tooltipRunCheckup}>
        <Button
          color={color}
          disabled={(disabled || Boolean(runDisabled) || running) && !error}
          onClick={prepareEstimationRun}
          leftIcon={
            <>
              <PlayCircleFilled />
              {(pending || running) && !error && <IconSpinner size={24} />}
            </>
          }
          size={size}
        >
          {!adaptiveMode && 'Запустить расчёт'}
        </Button>
      </Tooltip>
    )
  },
)
RunEstimation.displayName = 'RunEstimation'

const errorText = `Непредвиденная ошибка. Пожалуйста, обратитесь к разработчикам`

const statusErrorText =
  'Невозможно посчитать значение формулы. Поправьте формулу и повторите попытку'

const statusErrorEvent = estimationService.estimationStatusChanged.filter({
  fn: msg => msg.status === 'Error',
})
