import { forward } from 'effector'

import { FetchService } from '@gmini/utils'

import { removeAuthHeader, setAuthHeader } from '@gmini/common/lib/auth/auth'

import { tokenData$ } from '@gmini/common/lib/keycloakAuth'

import { refreshTokenFunc } from '@gmini/common/lib/keycloakAuth/auth/authData'

import { createEstimationService } from '@gmini/common/lib/Services/EstimationService'

import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'
import * as gStationApi from '@gmini/sm-api-sdk/lib/gStationConnect/gStationConnect'

import { gStationDocumentManagementUrl } from '../config'

import { diffService } from '../components/EstimationEditor/model/diffService'

import { notificationService } from './notificationService'

export const estimationService = createEstimationService({
  name: 'estimationService',
  diffService,
})
//TODO
// estimationServiceConnect()

forward({
  from: notificationService.message,
  to: estimationService.notification,
})

export const estimationFetch = new FetchService({
  baseUrl: '/api/estimations',
  refreshTokenFunc,
})

export const estimationReportsFetch = new FetchService({
  baseUrl: '/api/estimation-reports',
  refreshTokenFunc,
})

export const gStation = new FetchService({
  baseUrl: gStationDocumentManagementUrl,
})

tokenData$.watch(tokenData => {
  if (tokenData?.accessToken) {
    setAuthHeader(
      [estimationFetch, estimationReportsFetch, gStation],
      tokenData.accessToken,
    )
  } else {
    removeAuthHeader([estimationFetch, estimationReportsFetch, gStation])
  }
})

api.estimationConnect(estimationFetch)

api.estimationReportsConnect(estimationReportsFetch)

gStationApi.gStationConnect(gStation)
