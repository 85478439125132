import { Store } from 'effector'
import { useStore } from 'effector-react'
import React, { useEffect } from 'react'
import Scrollbars from 'react-custom-scrollbars'

import { SearchModel, useDiffMode } from '@gmini/common'

import { Nodes } from '@gmini/common/lib/classifier-service'

import { EstimationReport as EstimationReportApi } from '@gmini/sm-api-sdk/lib/EstimationApi/EstimationRepo/EstimationReport'

import { isEmpty } from 'ramda'

import { EstimationEntity } from '@gmini/common/lib/Services/EstimationService'

import { ReportTable } from '../../ReportTable/ReportTable'

import { systemColumn } from '../../ReportTable/systemColumn'

import {
  fetchReportResultDiff,
  previewResultDiff$,
} from '../model/reportDiffService'

import { useReportTableProps } from '../../ReportTable/useReportTableProps'

import { Pending } from './Report/Pending'

import { Refresh } from './Report/Refresh'
import {
  EstimationReportBottomDivider,
  EstimationReportWrap,
} from './EstimationReport.styled'

import {
  tablesContentView$,
  toggleTableVisibility,
} from './ToggleContent/model'
import { ReportItem } from './model/createReportDiffService'
import { isCompactView$ } from './SelectView/model'
import { withSubtotals$ } from './Subtotals/model'
import { EstimationReportHeader } from './EstimationReportHeader/EstimationReportHeader'

type EstimationReportProps = {
  action: React.ReactNode
  pending: boolean
  isGroupItem: (item: EstimationReportApi.Tree[number]) => boolean
  notStarted: boolean
  notActualResult: React.ReactNode
  searchModel: SearchModel
  showFooter: boolean
  selectViewerRefs: (value: Record<string, string[]>) => void
  nodes$: Store<Nodes>
  widthResultsResizableCol: number
  currentEstimation: EstimationEntity
}

export const EstimationReportDiff = ({
  action,
  notActualResult,
  pending,
  isGroupItem,
  notStarted,
  searchModel,
  showFooter,
  selectViewerRefs,
  nodes$,
  widthResultsResizableCol,
  currentEstimation,
}: EstimationReportProps) => {
  const { id, sourceVersion, targetVersion } = useDiffMode()
  const previewResult = useStore(previewResultDiff$)
  const showSubtotals = useStore(withSubtotals$)
  const allTablesVisible = useStore(tablesContentView$)
  const isCompactTableView = useStore(isCompactView$)
  const [currentReport, setCurrentReport] =
    React.useState<EstimationReportApi.TreeGroupItemDiff>()

  const containerRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    fetchReportResultDiff.submit({
      estimationId: id,
      sourceVersion,
      targetVersion,
    })
  }, [currentEstimation.id, id, sourceVersion, targetVersion])

  const { columnRenderer, itemRenderer, ContextMenu } = useReportTableProps({
    isCompactTableView,
    isGroupItem,
    nodes$,
    searchModel,
    selectViewerRefs,
  })

  const getDataColumns = React.useMemo(
    () => (table: ReportItem) =>
      [
        systemColumn,
        ...table.columns.map((column, index) => ({
          ...column,
          isLastColumn: index === table.columns.length - 1,
        })),
      ],
    [],
  )

  if (pending) {
    return <Pending text='Идет расчет результатов...' />
  }

  if (!previewResult && (notActualResult || notStarted)) {
    return <Refresh action={action} />
  }

  if (!previewResult || isEmpty(allTablesVisible)) {
    return null
  }

  return (
    <div
      ref={containerRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '17px',
        height: '100%',
      }}
    >
      {notActualResult}
      <EstimationReportWrap>
        <EstimationReportHeader<EstimationReportApi.TreeGroupItemDiff>
          rootGroupList={previewResult.rootGroupList}
          currentReport={currentReport}
          setCurrentReport={setCurrentReport}
          showFooter={showFooter}
          action={action}
        />

        {currentReport && (
          <Scrollbars
            // eslint-disable-next-line react/forbid-component-props
            style={{
              width: '100%',
              flexGrow: 1,
            }}
          >
            <ReportTable
              tables={
                previewResult.reportByRootGroupId[currentReport.elementId]
              }
              headerHeight={40}
              rowHeight={isCompactTableView ? 30 : 90}
              showSubtotals={showSubtotals}
              allTablesVisible={allTablesVisible[currentReport.elementId]}
              toggleTableVisibility={(index: number) =>
                toggleTableVisibility({
                  reportId: currentReport.elementId,
                  tableIndex: index,
                })
              }
              itemRenderer={itemRenderer}
              columnRenderer={columnRenderer}
              getDataColumns={getDataColumns}
              getDataRows={(table: ReportItem) => table.rows}
              getSubtotals={(table: ReportItem) => table.subTotals}
              getTablePath={(table: ReportItem) => table.path}
              widthResultsResizableCol={widthResultsResizableCol}
              getModifications={(table: ReportItem) => table?.modifications}
            />
            <ContextMenu />
          </Scrollbars>
        )}

        <EstimationReportBottomDivider />
      </EstimationReportWrap>
    </div>
  )
}
