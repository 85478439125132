import { useStore } from 'effector-react'

import { CompactView } from '../../../icons/CompactView'
import { ExpandedView } from '../../../icons/ExpandedView'

import {
  CompactIconButton,
  ExpandedIconButton,
  SelectWrapper,
} from './SelectView.styled'
import { switchTablesView, isCompactView$ } from './model'

export const SelectView = () => {
  const isCompactView = useStore(isCompactView$)

  return (
    <SelectWrapper>
      <CompactIconButton
        onClick={switchTablesView}
        selected={isCompactView}
        size='regular'
      >
        <CompactView />
      </CompactIconButton>
      <ExpandedIconButton
        onClick={switchTablesView}
        selected={!isCompactView}
        size='regular'
      >
        <ExpandedView />
      </ExpandedIconButton>
    </SelectWrapper>
  )
}
