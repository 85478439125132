import { Button, IconButton } from '@gmini/ui-kit'
import styled from 'styled-components'

export const EstimationDiffWrapper = styled.div`
  height: 100%;
  background: #4c5ecf;
  display: flex;
  flex-direction: column;
  padding: 4px;
`

export const EstimationDiffHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;
  padding: 4px 14px 8px 20px;
  color: #fff;
  font-weight: 700;
`

export const EstimationDiffContent = styled.div`
  background: #fff;
  flex: 1;
  border-radius: 8px 8px 0 0;
`

export const EstimationDiffHeaderCompare = styled.div`
  display: flex;
  align-items: center;
  gap: 0 16px;
`

export const CloseButton = styled(IconButton)`
  margin-left: auto;

  &&:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  &&:active {
    background: rgba(255, 255, 255, 0.2);
  }

  && {
    path {
      fill: #fff;
    }
  }
`

export const GoToNewVersionButton = styled(Button)`
  background: rgba(255, 255, 255, 0.2);
`
