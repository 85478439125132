import { createStore, Store, Event } from 'effector'

import { EstimationReport } from '@gmini/sm-api-sdk/lib/EstimationApi/EstimationRepo/EstimationReport'

import { Estimation } from '@gmini/sm-api-sdk/lib/EstimationApi/EstimationRepo/Estimation'

import { ResultColumn, calculateReportResult } from './calculateReportResult'

export type ReportItem = {
  path: string[]
  rows: EstimationReport.TreeBimItemDiff[]
  subTotals: EstimationReport.TreeGroupItemDiff
  columns: ResultColumn[]
  modifications?: EstimationReport.Modification[]
}

export type ReportResult = {
  reportByRootGroupId: Record<number, ReportItem[]>
  rootGroupList: EstimationReport.TreeGroupItemDiff[]
}

export function createReportDiffService<
  T extends { id: number; version: number },
>({
  entity$,
  status$,
  reset,
}: {
  entity$: Store<T | null>
  status$: Store<Estimation.Status | null>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reset: Event<any>
}) {
  const fetchReportResultDiff =
    EstimationReport.ReportResult.fetchVersionsDiff.createContext()
  const fetchReportResultDiffPending$ = fetchReportResultDiff.pending$

  const isGroupItem = (
    item: EstimationReport.Tree[number],
  ): item is EstimationReport.TreeGroupItem =>
    item.elementType === 'DynamicBaseGroup' ||
    item.elementType === 'UserClassifierGroup' ||
    item.elementType === 'DynamicGeneratedGroup'

  const previewResultDiff$ = createStore<ReportResult | null>(null)
    .on(fetchReportResultDiff.doneData, (_, data) =>
      calculateReportResult<
        EstimationReport.TreeBimItemDiff,
        EstimationReport.TreeGroupItemDiff,
        EstimationReport.ReportResultDiff
      >(data, isGroupItem),
    )
    .reset(reset)

  return {
    previewResultDiff$,
    reportPending$: fetchReportResultDiffPending$,
    fetchReportResultDiff,
    resetPreviewResultDiff: reset,
    isGroupItem,
  }
}
