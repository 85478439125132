//TODO!: transfer to ui-kit

export const DiffIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.222 23.3251C10.7649 23.3251 10.3943 22.9545 10.3943 22.4973V21.3533H4.62693C4.08515 21.3533 3.61963 21.1587 3.23038 20.7694C2.84112 20.3802 2.64648 19.9147 2.64648 19.3729V4.62725C2.64648 4.08158 2.84112 3.61273 3.23038 3.2207C3.61963 2.82868 4.08515 2.63267 4.62693 2.63267H10.3943V1.50277C10.3943 1.04563 10.7649 0.675049 11.222 0.675049C11.6792 0.675049 12.0498 1.04563 12.0498 1.50277V22.4973C12.0498 22.9545 11.6792 23.3251 11.222 23.3251ZM4.97516 17.9084C4.83384 18.07 4.94865 18.3229 5.16338 18.3229H10.1443C10.2824 18.3229 10.3943 18.211 10.3943 18.0729V12.3746C10.3943 12.1437 10.108 12.0363 9.95608 12.2101L4.97516 17.9084ZM13.7052 21.3533V12.4748C13.7052 12.2439 13.9915 12.1364 14.1435 12.3103L18.9343 17.7931C19.0863 17.967 19.3726 17.8595 19.3726 17.6286V4.62725H14.7025C14.1517 4.62725 13.7052 4.18075 13.7052 3.62996C13.7052 3.07917 14.1517 2.63267 14.7025 2.63267H19.3726C19.9183 2.63267 20.3871 2.82868 20.7791 3.2207C21.1712 3.61273 21.3672 4.08158 21.3672 4.62725V19.3729C21.3672 19.9147 21.1712 20.3802 20.7791 20.7694C20.3871 21.1587 19.9183 21.3533 19.3726 21.3533H13.7052Z'
      fill='white'
    />
  </svg>
)
